<template>
    <ValidationProvider
        v-slot="{ required, errors }"
        tag="div"
        :vid="vid"
        :name="`${label || id}`"
        :rules="rules"
        class="relative flex items-start min-w-fit my-2"
        :class="large ? 'leading-[1.6rem]' : 'leading-[1.4rem]'"
        mode="passive"
    >
        <input
            :id="id"
            v-model="checked"
            type="checkbox"
            :disabled="disabled"
            :required="required"
            :data-invalid="`${errors.length !== 0}`"
            class="flex-none appearance-none border rounded-[0.2rem] !leading-[inherit] !m-0 !p-0 cursor-pointer bg-white transition duration-200 disabled:border-grey peer
                disabled:bg-grey-light disabled:checked:after:text-grey-dark disabled:pointer-events-none checked:border-primary checked:bg-primary checked:after:block checked:after:absolute
                checked:after:top-0 checked:after:right-0 checked:after:bottom-0 checked:after:left-0 checked:after:text-center checked:after:text-white checked:after:content-['\2713']"
            :class="[
                large
                    ? 'w-[1.6rem] h-[1.6rem] checked:after:w-[1.6rem] checked:after:h-[1.6rem] checked:after:text-base checked:after:font-bold'
                    : 'w-[1.4rem] h-[1.4rem] checked:after:w-[1.4rem] checked:after:h-[1.4rem] checked:after:text-sm checked:after:font-normal',
                errors.length ? 'border-red' : 'border-grey-dark'
            ]"
            @change="onChange"
        >
        <label
            v-if="label"
            :for="id"
            class="!min-h-fit w-full m-0 py-0 !pl-4 pr-0 !font-normal !inline-block cursor-pointer hover:text-secondary peer-focus-visible:text-secondary"
            :class="{'pointer-events-none': disabled}"
        >
            {{ label }}
        </label>
        <slot
            v-else
            name="label"
        />
    </ValidationProvider>
</template>

<script>
export default {
    name: 'Checkbox',

    props: {
        id: {
            type: [String, Number],
            default: Date.now(),
        },

        vid: {
            type: String,
            default: null,
        },

        label: {
            type: String,
            default: null,
        },

        value: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        large: {
            type: Boolean,
            default: false,
        },

        rules: {
            type: [Object, String],
            default: null,
        },
    },

    emits: [
        'input',
        'change',
    ],

    computed: {
        checked: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            },
        },
    },

    methods: {
        onChange(event) {
            this.$emit('change', event.target.checked);
        },
    },
};
</script>

<template>
    <div
        v-if="filePath"
        class="logo"
    >
        <img
            :src="filePath"
            alt=""
            class="logo-img"
        >
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    name: 'CustomerLogo',

    computed: {
        ...mapState(['user']),
        ...mapState('customerLogo', [
            'filePath',
        ]),
    },

    created() {
        if (!this.filePath) {
            this.fetchCustomerLogo({ customerNo: this.user._customerNo });
        }
    },

    methods: {
        ...mapActions('customerLogo', ['fetchCustomerLogo']),
    },
};
</script>

<style lang="scss" scoped>
@use '~@variables' as variables;

.logo {
    display: none;

    @media #{variables.$md-and-up} {
        display: flex;
        align-items: center;
    }

    .logo-img {
        max-width: 10rem;
        max-height: 3.7rem;
        height: auto;
        padding: 0 1rem;
    }
}
</style>

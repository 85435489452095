import { bugsnag } from '@/libs/bugsnag.js';
import { deleteCustomerLogo, getCustomerLogo, updateCustomerLogo } from '@/modules/admin/customers/customer/logo/logo.service.api.js';
import { Const } from '@/utils/constants.js';

const state = {
    isFetching: false,
    isUpdating: false,
    isDeleting: false,
    errorMessage: null,
    filePath: null,
    updatedAt: null,
    updatedBy: null,
};

const mutations = {
    UPDATE_CUSTOMER_LOGO(state, payload) {
        const { path, updatedAt, updatedBy } = payload;

        state.filePath = path;
        state.updatedAt = updatedAt;
        state.updatedBy = updatedBy;
    },

    DELETE_CUSTOMER_LOGO(state) {
        state.filePath = null;
        state.updatedAt = null;
        state.updatedBy = null;
    },

    SET_IS_FETCHING(state, isFetching) {
        state.isFetching = isFetching;
    },

    SET_IS_UPDATING(state, isUploading) {
        state.isUpdating = isUploading;
    },

    SET_IS_DELETING(state, isDeleting) {
        state.isDeleting = isDeleting;
    },

    SET_ERROR_MESSAGE(state, message) {
        state.errorMessage = message;
    },
};

const actions = {
    setErrorMessage({ commit }, payload) {
        commit('SET_ERROR_MESSAGE', payload);
    },

    async fetchCustomerLogo({ commit }, payload) {
        commit('SET_ERROR_MESSAGE', null);
        commit('SET_IS_FETCHING', true);

        const { customerNo } = payload;

        try {
            const { data } = await getCustomerLogo(customerNo);

            if (data && data.path) {
                commit('UPDATE_CUSTOMER_LOGO', data);
            }
        } catch (error) {
            commit('SET_ERROR_MESSAGE', error.message);
        } finally {
            commit('SET_IS_FETCHING', false);
        }
    },

    async deleteCustomerLogo({ commit }, payload) {
        commit('SET_ERROR_MESSAGE', null);
        commit('SET_IS_DELETING', true);

        const { customerNo } = payload;

        try {
            await deleteCustomerLogo(customerNo);
            commit('DELETE_CUSTOMER_LOGO');
        } catch (error) {
            commit('SET_ERROR_MESSAGE', error.message);
        } finally {
            commit('SET_IS_DELETING', false);
        }
    },

    async updateCustomerLogo({ commit }, payload) {
        commit('SET_ERROR_MESSAGE', null);
        commit('SET_IS_UPDATING', true);

        const { customerNo, formData } = payload;

        try {
            const { data } = await updateCustomerLogo(customerNo, formData);

            commit('UPDATE_CUSTOMER_LOGO', data);
        } catch (error) {
            if (error.response.status === Const.HTTP_STATUS.UNPROCESSABLE_CONTENT) {
                commit('SET_ERROR_MESSAGE', error.response.data.message);
            } else {
                bugsnag.notify(error);
            }
        } finally {
            commit('SET_IS_UPDATING', false);
        }
    },
};

export default { state, mutations, actions, namespaced: true };

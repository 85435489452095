import AbstractRouterView from '@/components/layout/AbstractRouterView.vue';
import store from '@/store/store.js';
import { Const } from '@/utils/constants.js';

export default [
    {
        path: '/orders-overview/offers',
        name: 'offerList',
        meta: {
            authorized: () => store.state.user._type === Const.ACCOUNT_TYPE_SALES_REP,
        },
        component: () => import('@/modules/offers/OfferDatatablePage.vue'),
    },
    {
        path: '/discounts',
        component: AbstractRouterView,
        meta: {
            authorized: () => store.state.user._type === Const.ACCOUNT_TYPE_SALES_REP,
        },
        children: [
            {
                path: 'pending',
                name: 'pendingDiscounts',
                component: () => import('@/modules/discounts/DiscountsPendingOverview.vue'),
            },
            {
                path: 'rejected',
                name: 'rejectedDiscounts',
                component: () => import('@/modules/discounts/DiscountsRejectedOverview.vue'),
            },
            {
                path: 'history',
                name: 'approvedDiscounts',
                component: () => import('@/modules/discounts/DiscountsApprovedOverview.vue'),
            },
        ],
    },
];

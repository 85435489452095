import { bugsnag } from '@/libs/bugsnag.js';
import { switchLanguage } from '@/libs/i18n/i18n.js';
import { setupLaravelEcho } from '@/libs/laravel-echo.js';
import { setupMoment } from '@/libs/moment.js';
import { impersonateCustomerUser } from '@/modules/admin/customers/customer/user-management/user-management.api.js';
import { impersonateUser } from '@/modules/employees/users.api.js';
import { getAuthUserState, getRegions, logIn } from '@/modules/unauthenticated/login/login.api.js';
import { getPersistedState, persistState, resolveUserLocale } from '@/store/store-helpers.js';
import store from '@/store/store.js';

const state = {
    authenticated: false,
    userLocale: resolveUserLocale(),
    subsidiary: null,
    regions: [],
};

const mutations = {
    setAuthenticated(state) {
        state.authenticated = true;

        persistState('authenticated', true);
    },

    setUnauthenticated(state) {
        state.authenticated = false;

        persistState('authenticated', false);
    },

    setRegions(state, regions) {
        state.regions = regions;

        persistState('regions', JSON.stringify(regions));
    },

    setUserLocale: (state, locale) => {
        state.userLocale = locale;

        persistState('locale', locale);
    },

    setSubsidiary(state, subsidiary) {
        state.subsidiary = subsidiary;

        persistState('subsidiary', JSON.stringify(subsidiary));
    },
};

const getters = {
    authenticated: () => state.authenticated,

    userLocale: state => state.userLocale,

    regions: state => state.regions,

    subsidiary: state => state.subsidiary,
};

const actions = {
    async setSubsidiary({ commit, dispatch }, subsidiary) {
        commit('setSubsidiary', subsidiary);

        const persistedLocale = getPersistedState('locale', false);

        const locale = persistedLocale
            ? subsidiary.locales.find(locale => locale === persistedLocale || locale.startsWith(persistedLocale.split('-').at(0))) ?? subsidiary.defaultLocale
            : subsidiary.defaultLocale;

        await dispatch('setUserLocale', locale);
    },

    async setUnauthenticated({ commit }) {
        commit('setUnauthenticated');
        store.commit('updateIsLoggedOut', true);
    },

    async setUserLocale({ commit, getters }, locale) {
        try {
            await switchLanguage(locale, getters.authenticated);
        } catch (error) {
            bugsnag.notify(error);
        }

        commit('setUserLocale', locale);
    },

    async fetchRegions({ commit }) {
        const { data: regions } = await getRegions();

        commit('setRegions', regions);
    },

    async resolveSubsidiary({ dispatch, getters }) {
        const userLocale = getters.userLocale;
        const [, resolvedCountryCode] = userLocale.split('-');
        const persistedSubsidiary = getPersistedState('subsidiary');

        if (persistedSubsidiary) {
            await dispatch('setSubsidiary', persistedSubsidiary);
        } else {
            // Selects the most relevant subsidiary to use based on the country code of selected locale
            const resolvedSubsidiary = getters.regions.reduce((result, { subsidiaries }) => {
                const matchingSubsidiaries = subsidiaries.filter(({ countryCode }) => countryCode === resolvedCountryCode);
                const matchingSubsidiary = matchingSubsidiaries.find(({ locales }) => locales.includes(userLocale))?.subsidiary ?? null;

                return matchingSubsidiary ?? result;
            }, null);

            await (resolvedSubsidiary ? dispatch('setSubsidiary', resolvedSubsidiary) : dispatch('setUserLocale', userLocale));
        }
    },

    async setUpUnauthenticatedState({ commit, dispatch }) {
        commit('setUnauthenticated');

        await dispatch('resolveSubsidiary');
    },

    async setUpAuthenticatedState({ commit, dispatch }) {
        commit('setAuthenticated');

        const persistedSubsidiary = getPersistedState('subsidiary');

        if (persistedSubsidiary) {
            setupLaravelEcho(persistedSubsidiary.key);
            setupMoment(persistedSubsidiary);

            await dispatch('resolveSubsidiary');
        }

        const { data } = await getAuthUserState();

        store.commit('setAuthUserState', data);
    },

    async authenticate({ commit }, payload) {
        const data = await logIn(payload);

        if (data.authenticated) {
            commit('setAuthenticated');
            store.commit('updateIsLoggedOut', false);
        }

        return data;
    },

    async impersonateUser(_, { username, isCustomer = false }) {
        await (isCustomer ? impersonateCustomerUser(username) : impersonateUser(username)); // TODO: These endpoints should return auth user state so that we don't have to make another request

        const { data } = await getAuthUserState();

        store.commit('setAuthUserState', data);

        if (isCustomer) {
            store.commit('setAvailableOrderJourneys', []); // Reset order journeys in case customer has no access to one of them. Order journeys will be fetched again in the middleware
        }
    },
};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

<template>
    <DropDownMenu>
        <template #title>
            {{ $t('adminMainMenu.toolBox') }}
        </template>
        <template #dropdown>
            <li>
                <router-link to="/toolbox/item-visibility-management">
                    {{ $t('adminMainMenu.visibility') }}
                </router-link>
            </li>
            <li>
                <router-link to="/toolbox/e-catalog-management">
                    {{ $t('adminMainMenu.eCatalog') }}
                </router-link>
            </li>
            <li>
                <router-link to="/toolbox/baskets">
                    {{ $t('adminBaskets.menuItem') }}
                </router-link>
            </li>
            <li>
                <router-link to="/toolbox/jobs-monitor">
                    {{ $t('adminMainMenu.jobsMonitor') }}
                </router-link>
            </li>
            <li>
                <router-link to="/toolbox/item-visibility-checker">
                    {{ $t('adminMainMenu.itemcheck') }}
                </router-link>
            </li>
            <li v-if="isUnitedStatesAdmin">
                <router-link to="/toolbox/sales-rep-management">
                    Sales Rep Management
                </router-link>
            </li>
            <li v-if="canManageOffers">
                <router-link to="/toolbox/pre-order-management">
                    {{ $t('offers.offers') }}
                </router-link>
            </li>
            <li v-if="canManageOffers">
                <router-link to="/toolbox/offers">
                    {{ $t('offers.offerListing') }}
                </router-link>
            </li>
            <li v-if="canManageFoc">
                <router-link to="/toolbox/foc-management">
                    {{ $t('adminMainMenu.focManager') }}
                </router-link>
            </li>
            <li v-if="disputesAvailable">
                <router-link to="/toolbox/dispute-workstation">
                    {{ $t('disputes.disputeWorkstation') }}
                </router-link>
            </li>
            <li v-if="isWorkflowManagementEnabled">
                <router-link to="/toolbox/workflow-management/workflows">
                    {{ $t('adminMainMenu.workflows') }}
                </router-link>
            </li>
        </template>
    </DropDownMenu>
</template>

<script>
import DropDownMenu from '@/components/menu/DropDownMenu.vue';
import { Const } from '@/utils/constants';

export default {
    name: 'ToolboxDropdownMenu',

    components: {
        DropDownMenu,
    },

    computed: {
        canManageOffers() {
            return this.$store.state.subsidiary.offersEnabled && this.$store.getters.getUserCan(Const.PERMISSION_CAN_MANAGE_OFFERS);
        },

        isUnitedStatesAdmin() {
            return this.$store.state.subsidiary.id === Const.SUBSIDIARIES.UNITED_STATES && this.$store.getters.getUserType === Const.ACCOUNT_TYPE_ADMIN;
        },

        canManageFoc() {
            return this.$store.getters.getUserCan(Const.PERMISSIONS.CAN_MANAGE_FOC);
        },

        disputesAvailable() {
            return this.$store.state.subsidiary.disputesEnabled;
        },

        isWorkflowManagementEnabled() {
            return this.$store.state.subsidiary.isWorkflowManagementEnabled;
        },
    },
};
</script>

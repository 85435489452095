<template>
    <button
        type="button"
        class="flex gap-2 focus:outline-none justify-center place-items-center font-bold capitalize bg-transparent leading-[2rem] touch-manipulation"
        :class="[
            {'text-grey-dark': kind === 'neutral'},
            {'text-white hover:text-white focus-visible:text-white': kind === 'white'},
            {'text-primary hover:text-secondary focus-visible:text-secondary': kind === 'primary'},
            {'text-red-dark hover:text-red-darkest--hover focus-visible:text-red-darkest--hover': kind === 'danger'},
            {'disabled:opacity-50 pointer-events-none': disabled},
        ]"
        :disabled="disabled"
    >
        <slot />
        <span
            v-if="text"
            class="capitalize"
        >
            {{ text }}
        </span>
    </button>
</template>

<script>
export default {
    name: 'ButtonPlain',

    props: {
        text: {
            type: String,
            default: null,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        kind: {
            type: String,
            default: 'primary',
            validator(value) {
                const isValid = ['primary', 'danger', 'neutral', 'white'].includes(value);

                if (!isValid) {
                    console.warn('Invalid kind of button provided');
                }

                return isValid;
            },
        },
    },
};
</script>

<template>
    <div class="flex items-center">
        <i class="fa fa-language fa-xl mr-2" />
        <select
            :value="userLocale"
            class="bg-transparent text-primary"
            @change="switchLocale"
        >
            <option
                v-for="{localeCode, name} in localeOptions"
                :key="localeCode"
                :value="localeCode"
            >
                {{ name }}
            </option>
        </select>
    </div>
</template>

<script setup>
import { bugsnag } from '@/libs/bugsnag.js';
import { formatLanguageName } from '@/utils/generic.js';
import { computed } from 'vue';
import store from '@/store/store.js';

const props = defineProps({
    userLocale: {
        type: String,
        required: true,
    },

    subsidiaryLocales: {
        type: Array,
        required: true,
    },
});

const localeOptions = computed(() => props.subsidiaryLocales.map(localeCode => {
    const [languageCode, regionCode] = localeCode.split('-');

    return {
        localeCode,
        name: `${formatLanguageName(localeCode, languageCode)} (${regionCode})`,
    };
}).sort((a, b) => a.name.localeCompare(b.name)));

const switchLocale = async event => {
    const locale = event.target.value;

    try {
        await store.dispatch('globalStore/setUserLocale', locale);

        if (store.getters['globalStore/authenticated']) {
            store.commit('updateUserLocale', locale);
        }
    } catch (error) {
        bugsnag.notify(error);
    }
};

</script>

<template>
    <div
        v-show="isCustomerContext"
        class="flex items-center"
    >
        <MiniBasketSummary
            v-if="deliveryAddresses.length"
            :delivery-address="currentDeliveryAddress"
            :net-amount="formatPrice(basket.totals.netAmount)"
            :total-quantity="basket.totalQuantity"
            :should-show-price="shouldShowPrice"
            :order-journey="orderJourney"
            :is-pre-order-from-template="basket.isPreOrderFromTemplate"
            :is-fashion-contract="basket.isFashionContract"
            :format-number="formatNumber"
            class="max-w-[calc(100vw-22rem)] cursor-pointer hover:text-secondary"
            @click.native.stop="showMiniBasketSidebar"
        />

        <div class="relative z-10">
            <!-- Page overlay -->
            <div
                v-show="expanded"
                class="fixed top-0 right-0 bottom-0 left-0 bg-black opacity-20"
                @click.stop="hideMiniBasketSidebar"
            />

            <!-- Mini-basket sidebar (only visible on desktop) -->
            <FadeTransition name="slide-fade">
                <div
                    v-show="expanded"
                    class="fixed top-0 right-0 bottom-0 grid grid-rows-[repeat(auto-fit,minmax(4rem,min-content))] max-w-[45rem] w-full bg-white shadow-xl"
                >
                    <div
                        v-if="deliveryAddresses.length > 0"
                        class="flex flex-col pb-4 sm:pb-6"
                    >
                        <div class="flex items-center justify-between h-[6.5rem] px-6">
                            <MiniBasketSummary
                                :delivery-address="currentDeliveryAddress"
                                :net-amount="formatPrice(basket.totals.netAmount)"
                                :total-quantity="basket.totalQuantity"
                                :should-show-price="shouldShowPrice"
                                :order-journey="orderJourney"
                                :is-pre-order-from-template="basket.isPreOrderFromTemplate"
                                :is-fashion-contract="basket.isFashionContract"
                                :format-number="formatNumber"
                                class="pr-6 w-full"
                            />
                            <ButtonPlain @click.native.stop="hideMiniBasketSidebar">
                                <i class="fa fa-close fa-xl" />
                            </ButtonPlain>
                        </div>
                        <MiniBasketAddressSelection
                            v-if="!isCustomizedOrder && !isPreOrderFromTemplate"
                            :delivery-addresses="deliveryAddresses"
                            :distribution-centers="distributionCenters"
                            :selected-delivery-address="currentDeliveryAddress"
                            :selected-distribution-center="currentDistributionCenter"
                            :basket-delivery-customer-no="basketDeliveryCustomerNo"
                            :basket-delivery-address-no="basketDeliveryAddressNo"
                            :basket-distribution-center-no="basketDistributionCenterNo"
                            :customer-no="basket.customerNo"
                            @deliveryAddressSelected="onDeliveryAddressSelected"
                            @distributionCenterSelected="onDistributionCenterSelected"
                        />
                    </div>

                    <MiniBasketHeader
                        :basket="basket"
                        :show-basket-actions="true"
                        @showCopyBasketModal="toggleBasketCopyModal"
                    />

                    <div class="flex flex-col h-full divide-y divide-grey">
                        <div
                            v-if="basketIsEmpty"
                            class="flex flex-col items-center justify-center h-screen"
                        >
                            {{ $t('basket.isEmpty') }}
                        </div>
                        <MiniBasketItems
                            v-else
                            :basket="basket"
                            :should-show-price="shouldShowPrice"
                            :format-number="formatNumber"
                            :format-price="formatPrice"
                            :is-customized-order="isCustomizedOrder"
                        />
                        <MiniBasketFooter
                            :basket="basket"
                            :is-customized-order="isCustomizedOrder"
                            :basket-is-empty="basketIsEmpty"
                            :should-show-price="shouldShowPrice"
                            :format-price="formatPrice"
                            :format-number="formatNumber"
                            :format-units="formatUnits"
                            @hideMiniBasketSidebar="hideMiniBasketSidebar"
                        >
                            <MiniBasketPreOrderFromTemplateActions
                                v-if="basket.isPreOrderFromTemplate"
                                :basket="basket"
                                :basket-is-empty="basketIsEmpty"
                            />
                        </MiniBasketFooter>
                    </div>
                </div>
            </FadeTransition>
        </div>

        <CopyBasketModal
            v-if="showBasketCopyModal"
            :basket="basket"
            @close="toggleBasketCopyModal"
            @basketCopied="redirectAfterBasketCopy"
        />
    </div>
</template>

<script>
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import FadeTransition from '@/components/fade-transition/FadeTransition.vue';
import localisedCurrencyFormat from '@/filters/localised-currency-format.js';
import { basketIsNonEditableFOC } from '@/modules/baskets/basket.service.js';
import MiniBasketAddressSelection from '@/modules/baskets/mini-basket/MiniBasketAddressSelection.vue';
import MiniBasketFooter from '@/modules/baskets/mini-basket/MiniBasketFooter.vue';
import MiniBasketHeader from '@/modules/baskets/mini-basket/MiniBasketHeader.vue';
import MiniBasketItems from '@/modules/baskets/mini-basket/MiniBasketItems.vue';
import MiniBasketPreOrderFromTemplateActions from '@/modules/baskets/mini-basket/preorder-from-template/MiniBasketPreOrderFromTemplateActions.vue';
import MiniBasketSummary from '@/modules/baskets/mini-basket/MiniBasketSummary.vue';
import CopyBasketModal from '@/modules/baskets/pending/CopyBasketModal.vue';
import { Const } from '@/utils/constants.js';
import { laravelEchoClient } from '@/libs/laravel-echo.js';
import { bugsnag } from '@/libs/bugsnag.js';
import { emitter } from '@/utils/event-emitter.js';
import { localisedNumberFormat } from '@/utils/generic.js';

export default {
    name: 'MiniBasket',

    components: {
        MiniBasketPreOrderFromTemplateActions,
        MiniBasketFooter,
        MiniBasketHeader,
        MiniBasketAddressSelection,
        MiniBasketItems,
        CopyBasketModal,
        FadeTransition,
        MiniBasketSummary,
        ButtonPlain,
    },

    props: {
        basket: {
            type: Object,
            required: true,
        },

        deliveryAddresses: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            currentBasketNo: null,
            currentDeliveryAddressNo: null,
            currentDistributionCenterNo: null,
            expanded: false,
            showBasketCopyModal: false,
            ORDER_JOURNEY: Const.ORDER_JOURNEY,
        };
    },

    computed: {
        isSalesRep() {
            return this.$store.state.user._type === Const.ACCOUNT_TYPE_SALES_REP;
        },

        isCustomer() {
            return this.$store.state.user._type === Const.ACCOUNT_TYPE_CUSTOMER;
        },

        shouldShowPrice() {
            return this.$store.state.preferences.priceDisplay === 'show' && !this.$store.getters.customerIsStore;
        },

        basketDeliveryCustomerNo() {
            return this.basket?.customerNo;
        },

        basketDeliveryAddressNo() {
            return this.basket?.oneTimeShipTo?.no || this.basket?.shipTo?.no;
        },

        basketDistributionCenterNo() {
            return this.basket?.distributionCenterNo ?? null;
        },

        currentDeliveryAddress() {
            return this.deliveryAddresses.length ? this.deliveryAddresses.find(({ no }) => no === this.currentDeliveryAddressNo) : null;
        },

        currentDistributionCenter() {
            return this.distributionCenters.length ? this.distributionCenters.find(({ addressNo }) => addressNo === this.currentDistributionCenterNo) : null;
        },

        distributionCenters() {
            return this.$store.getters.getSubsidiarySettings?.usePartnerDistributors ? this.$store.getters.customerDistributionCenters[this.basket.customerNo] ?? [] : [];
        },

        isCustomizedOrder() {
            return this.basket?.orderType === Const.ORDER_TYPE.CUSTOMIZED_ORDER;
        },

        isPreOrderFromTemplate() {
            return this.basket?.isPreOrderFromTemplate;
        },

        isCustomerContext() {
            return this.isSalesRep ? Boolean(this.$route.params?.basketNo): this.isCustomer;
        },

        /**
         * @returns {string}
         */
        orderJourney() {
            return this.basket.orderJourney;
        },

        basketIsEmpty() {
            return this.basket.totalRequestedQuantity === 0;
        },

        isMobileViewport() {
            return this.$store.getters.isMobileViewport;
        },

        selectedDeliveryAddressNo: {
            get() {
                return this.basketDeliveryAddressNo;
            },

            set(value) {
                this.currentDeliveryAddressNo = value;
            },
        },

        selectedDistributionCenterNo: {
            get() {
                return this.basketDistributionCenterNo;
            },

            set(value) {
                this.currentDistributionCenterNo = value;
            },
        },
    },

    watch: {
        $route() {
            this.hideMiniBasketSidebar();
        },
    },

    created() {
        this.handleBasketUpdateSubscriptions(Number(this.basket.no));

        this.currentDeliveryAddressNo = this.basketDeliveryAddressNo;
        this.currentDistributionCenterNo = this.basketDistributionCenterNo;
    },

    beforeDestroy() {
        this.unsubscribeFromBasketUpdates(this.currentBasketNo);
    },

    methods: {
        subscribeToBasketUpdates(basketNo) {
            laravelEchoClient.private(`basket.${this.$store.state.subsidiary.id}.${basketNo}`)
                .listen('.updated', ({ basket, updatedBy }) => {
                    this.updateBasket({ basket, updatedBy });
                });
        },

        unsubscribeFromBasketUpdates(basketNo) {
            laravelEchoClient.leave(`basket.${this.$store.state.subsidiary.id}.${basketNo}`);
        },

        handleBasketUpdateSubscriptions(basketNo) {
            if (!basketNo) {
                return;
            }

            if (basketNo !== this.currentBasketNo && this.$route.name !== 'orderConfirmation') {
                this.subscribeToBasketUpdates(basketNo);
                this.unsubscribeFromBasketUpdates(this.currentBasketNo);

                this.currentBasketNo = basketNo;
            }
        },

        onDeliveryAddressSelected(selectedAddressNo) {
            this.selectedDeliveryAddressNo = selectedAddressNo;
        },

        onDistributionCenterSelected(selectedAddressNo) {
            this.selectedDistributionCenterNo = selectedAddressNo;
        },

        async showMiniBasketSidebar() {
            // On mobile, we navigate directly to basket page instead of showing mini-basket sidebar
            if (this.isMobileViewport) {
                try {
                    await this.$router.push({
                        path: `/orders/${this.basket.no}/basket-contents`,
                        query: {
                            ...this.$route.query,
                            isPreOrderFromTemplate: this.isPreOrderFromTemplate,
                        },
                    });
                } catch (error) {
                    bugsnag.notify(error);
                }

                return;
            }

            this.expanded = true;

            document.querySelector('body').classList.add('no-scroll');
        },

        hideMiniBasketSidebar() {
            this.expanded = false;

            document.querySelector('body').classList.remove('no-scroll');
        },

        toggleBasketCopyModal() {
            this.showBasketCopyModal = !this.showBasketCopyModal;
        },

        async redirectAfterBasketCopy(basket) {
            try {
                switch (true) {
                    case basket.status === Const.BASKET_STATUS.FAILED:
                    case basket.status === Const.BASKET_STATUS.SENT:
                        this.hideMiniBasketSidebar();

                        return;
                    case basket.orderType === Const.ORDER_TYPE.CUSTOMIZED_ORDER:
                        await this.$router.push(`/product-customization/orders/${basket.no}/product-sets`);

                        return;
                    case basketIsNonEditableFOC(basket, this.$store.getters.getUserType):
                        await this.$router.push(`/orders/${basket.no}/checkout`);

                        return;
                }

                await this.$router.push(`/orders/${basket.no}`);
            } catch (error) {
                bugsnag.notify(error);
            }
        },

        async updateBasket({ basket, updatedBy }) {
            emitter.$emit('basketUpdated', { basket, updatedBy });

            try {
                await this.$store.dispatch('updateBasket', basket);
            } catch (error) {
                bugsnag.notify(error);
            }
        },

        formatPrice(value) {
            return localisedCurrencyFormat(value, this.$store.state.subsidiary.locale, this.basket.currencyCode);
        },

        formatNumber(value) {
            return localisedNumberFormat(value, this.$store.state.subsidiary.locale);
        },

        formatUnits(quantity) {
            return `${this.formatNumber(quantity)} ${quantity === 1 ? this.$t('basket.unit') : this.$t('basket.units')}`;
        },
    },
};
</script>

import roundToNearest from '@/filters/roundToNearest.js';

export default function (number, shouldRoundToNearest = false) {
    const toNearest = shouldRoundToNearest || 0.01;

    if (toNearest) {
        return roundToNearest(number, toNearest);
    }

    return number;
}


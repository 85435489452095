import AbstractRouterView from '@/components/layout/AbstractRouterView.vue';
import Dashboard from '@/modules/dashboard/Dashboard.vue';
import store from '@/store/store.js';
import { Const } from '@/utils/constants';

function transformOrderProps(route) {
    return {
        ...route.params,
        ...route.query,
        isPreOrderFromTemplate: Boolean(route.query.isPreOrderFromTemplate),
        isCustomizedOrder: route.query.isCustomizedOrder === 'true',
        basketNo: Number(route.params.basketNo),
    };
}

export default [
    // Customer landing page
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            authorized: () => store.state.user._type === Const.ACCOUNT_TYPE_CUSTOMER,
        },
    },
    {
        path: '/orders/:basketNo',
        component: AbstractRouterView,
        props: true,
        meta: {
            ordersBase: true,
            authorized: () => [Const.ACCOUNT_TYPE_CUSTOMER, Const.ACCOUNT_TYPE_SALES_REP].includes(store.state.user._type),
        },
        children: [
            {
                path: '',
                name: 'productList',
                component: () => import('@/modules/plp/ProductLandingPage.vue'),
                props: transformOrderProps,
            },
            {
                path: 'products/:itemNo',
                name: 'productDetails',
                component: () => import('@/modules/pdp/ProductDetails.vue'),
                props: transformOrderProps,
            },
            {
                path: 'basket-contents',
                name: 'basketContents',
                component: () => import('@/modules/baskets/basket/Basket.vue'),
                props: transformOrderProps,
            },
            {
                path: 'order-confirmation',
                name: 'orderConfirmation',
                component: () => import('@/modules/order-confirmation/OrderConfirmation.vue'),
                props: transformOrderProps,
            },
            {
                path: 'checkout',
                name: 'checkout',
                component: () => import('@/modules/checkout/Checkout.vue'),
                props: transformOrderProps,
            },
        ],
    },
    {
        path: '/offers/:basketNo',
        component: AbstractRouterView,
        props: true,
        meta: {
            authorized: () => [Const.ACCOUNT_TYPE_CUSTOMER, Const.ACCOUNT_TYPE_SALES_REP].includes(store.state.user._type),
        },
        children: [
            {
                path: '',
                name: 'offerHistory',
                component: () => import('@/modules/offers/offer/HistoricOffer.vue'),
                props: route => ({ basketNo: Number(route.params.basketNo) }),
            },
        ],
    },
    {
        path: '/downloads',
        component: AbstractRouterView,
        meta: {
            authorized: () => store.state.user._type === Const.ACCOUNT_TYPE_CUSTOMER,
        },
        children: [
            {
                path: 'assets/:brand',
                name: 'assetDownload',
                component: () => import('@/modules/download/AssetDownloadComponent.vue'),
                props: true,
            },
            {
                path: 'e-catalogs/:key?',
                name: 'eCatalogDownload',
                component: () => import('@/modules/customer/CustomerDownloadECatalog.vue'),
                props: route => ({ key: route.params.key, customerNo: store.getters.getRealCustomerNo }),
            },
            {
                path: 'product-collection-data',
                name: 'eanCodeDownload',
                component: () => import('@/modules/download/ProductCollectionDataDownload.vue'),
                props: () => ({ customerNo: store.getters.getRealCustomerNo }),
            },
        ],
    },
    {
        path: '/selective-distribution-policy',
        component: () => import('@/modules/selective-distribution-policy/SelectiveDistributionPolicy.vue'),
        redirect: '/selective-distribution-policy/introduction',
        meta: {
            authorized: () => store.state.subsidiary.sdpScoringStrategy === Const.SDP_SCORING_STRATEGY.QUESTIONNAIRE,
        },
        children: [
            {
                path: 'introduction',
                component: () => import('@/modules/selective-distribution-policy/SdpIntroduction.vue'),
            },
            {
                path: 'asics-fair-play-rules',
                component: () => import('@/modules/selective-distribution-policy/SdpAsicsFairPlayRules.vue'),
            },
            {
                path: 'general-acceptance-criteria',
                component: () => import('@/modules/selective-distribution-policy/SdpGeneralAcceptanceCriteria.vue'),
            },
            {
                path: 'asics-authorized-retailer-logo',
                component: () => import('@/modules/selective-distribution-policy/SdpAsicsAuthorizedRetailerLogo.vue'),
            },
            {
                path: 'brand-guidelines',
                component: () => import('@/modules/selective-distribution-policy/SdpBrandGuidelines.vue'),
            },
            {
                path: 'tpp-tiering',
                component: () => import('@/modules/selective-distribution-policy/SdpTppTiering.vue'),
            },
        ],
    },
];

<template>
    <ModalDialog
        size="small"
        :closable="false"
        @close="cancel"
    >
        <template #header-title>
            {{ $t('general.titleConfirmation') }}
        </template>

        <template #message>
            {{ $t('general.confirmationMessage') }}
        </template>

        <div class="action-buttons">
            <Button
                kind="primary-alt"
                :text="$t('general.buttonNo')"
                @click.native="cancel"
            />
            <Button
                :text="$t('general.buttonYes')"
                @click.native="confirm"
            />
        </div>
    </ModalDialog>
</template>

<script>
import Button from '@/components/button/Button.vue';
import ModalDialog from '@/components/dialogs/ModalDialog.vue';

export default {
    name: 'ConfirmationDialog',

    components: {
        Button,
        ModalDialog,
    },

    emits: [
        'cancel',
        'confirm',
    ],

    methods: {
        cancel() {
            this.$emit('cancel');
        },

        confirm() {
            this.$emit('confirm');
        },
    },
};
</script>

<template>
    <div class="flex flex-col justify-between h-full overflow-hidden divide-y divide-grey">
        <div class="h-full overflow-auto">
            <table class="w-full">
                <thead>
                    <tr class="bg-green-lightest">
                        <th class="p-2 pl-6 text-left">
                            {{ useShipMonthForPreorderTemplate ? $t('general.labelMonth') : $t('preorder.dates') }}
                        </th>
                        <th class="p-2 text-left">
                            {{ $t('orderDetails.quantity') }}
                        </th>
                        <th
                            v-if="shouldShowPrice"
                            class="p-2 text-left"
                        >
                            {{ $t('general.labelTotals') }}
                        </th>
                        <th class="p-2" />
                    </tr>
                </thead>
                <tbody class="divide-y divide-grey-light">
                    <template v-for="(summary, key) in groupedItems">
                        <tr
                            :key="key"
                            :class="{'bg-grey-lightest': state.expanded[key]}"
                        >
                            <td class="p-2 pl-6">
                                {{ summary.formattedDate }}
                            </td>
                            <td class="p-2">
                                {{ formatNumber(summary.totalQuantity) }}
                            </td>
                            <td
                                v-if="shouldShowPrice"
                                class="p-2"
                            >
                                {{ formatPrice(summary.totalNetAmount) }}
                            </td>
                            <td class="p-2">
                                <ButtonPlain
                                    kind="neutral"
                                    @click.native="toggleExpanded(key)"
                                >
                                    <i :class="['fa', state.expanded[key] ? 'fa-minus' : 'fa-plus']" />
                                </ButtonPlain>
                            </td>
                        </tr>
                        <MiniBasketPreOrderFromTemplateGroupItems
                            :key="`${key}-content`"
                            :basket-info="basketInfo"
                            :expanded="state.expanded[key]"
                            :should-show-price="shouldShowPrice"
                            :items-by-date="summary.itemsByDate"
                            :year-month="key"
                        />
                    </template>
                </tbody>
            </table>
        </div>
        <BasketSummaryTotals
            :should-pay-in-advance="false"
            :total-quantity-by-asics="totalQuantitySuppliedByAsics"
            :total-quantity-by-distributors="0"
            :net-amount="basket.totals.netAmount"
            :net-service-charge="basket.totals.netServiceCharge"
            :gross-service-charge="basket.totals.grossServiceCharge"
            :vat-amount="basket.totals.vatAmount"
            :amount-to-pay="basket.totals.amountToPay"
            :should-show-price="shouldShowPrice"
            :format-units="formatUnits"
            :format-price="formatPrice"
        />
    </div>
</template>

<script setup>
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import { useI18n } from '@/composables/use-i18n.js';
import basketService from '@/modules/baskets/basket.service.js';
import BasketSummaryTotals from '@/modules/baskets/basket/basket-summary/BasketSummaryTotals.vue';
import MiniBasketPreOrderFromTemplateGroupItems from '@/modules/baskets/mini-basket/preorder-from-template/MiniBasketPreOrderFromTemplateGroupItems.vue';
import store from '@/store/store.js';
import { computed, reactive, watch } from 'vue';

const props = defineProps({
    basket: {
        type: Object,
        required: true,
    },

    shouldShowPrice: {
        type: Boolean,
        required: true,
    },

    formatNumber: {
        type: Function,
        required: true,
    },

    formatPrice: {
        type: Function,
        required: true,
    },
});

const { t } = useI18n();

const useShipMonthForPreorderTemplate = computed(() => Boolean(store.getters.getSubsidiarySettings.useShipMonthForPreorderTemplate));
const formatGroupedLineKey = date => useShipMonthForPreorderTemplate.value ? basketService.resolveRequestedDeliveryDateForPreOrderTemplate(date).substring(0, 7) : date;
const state = reactive({
    expanded: Object.fromEntries(Object.keys(props.basket.groupedLines).map(date => [formatGroupedLineKey(date), false])),
});

const basketInfo = computed(() => ({
    basketNo: props.basket.no,
    orderJourney: props.basket.orderJourney,
    currencyCode: props.basket.currencyCode,
    customerDistributors: props.basket.partners ?? [],
    isCustomizedOrder: props.isCustomizedOrder,
}));

const groupedItems = computed(() => basketService.getPreOrderFromTemplateGroupedLines(props.basket.groupedLines, store.getters.userLocale, useShipMonthForPreorderTemplate.value));
const totalQuantitySuppliedByAsics = computed(() => props.basket.lines.reduce((accumulator, { partnerNo, quantity }) => !partnerNo ? accumulator + quantity : accumulator, 0));
const formatUnits = totalQuantity => `${props.formatNumber(totalQuantity)} ${totalQuantity === 1 ? t('basket.unit') : t('basket.units')}`;
const toggleExpanded = key => {
    state.expanded[key] = !state.expanded[key];
};

watch(() => props.basket.groupedLines, () => {
    state.expanded = Object.fromEntries(Object.keys(props.basket.groupedLines).map(date => [formatGroupedLineKey(date), false]));
});
</script>

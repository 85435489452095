import { sumBy, toArray } from '@/utils/collection.js';
import { Const } from '@/utils/constants';
import { addMonth, toDateParts } from '@/utils/generic.js';

export default {
    subsidiary: null,

    setSubsidiary(subsidiary) {
        this.subsidiary = subsidiary;
    },

    getSizes() {
        return Const.SIZE_GRID.map(size => ({
            label: size,
            value: `size${size}`,
        }));
    },

    getSizeTypes() {
        return Const.SIZE_GRID.map(size => ({
            text: size,
            value: `size${size}`,
        }));
    },

    // TODO: split this function into multiple (AJ)
    // eslint-disable-next-line max-lines-per-function
    transformBasket(basket) {
        if (!Object.entries(basket).length) {
            return basket;
        }

        const groupedLines = {};

        basket.reviewRequested = false;
        basket.containsItemsSuppliedByDistributor = false;

        for (const item of basket.lines) {
            const { requestedDeliveryDate, originalDeliveryDate, itemNo, itemName, colorCode, colorName, lineType, recipeId, recipeSetId, unitPrice, quantity, requestedQuantity, gender } = item;
            const colorGroupCode = basket.orderType === Const.ORDER_TYPE.CUSTOMIZED_ORDER
                ? requestedDeliveryDate + itemNo + colorCode + lineType + recipeId + unitPrice
                : requestedDeliveryDate + itemNo + colorCode + lineType;

            const lineTotalUnitPrice = unitPrice * quantity;
            const lineDiscount = Math.round(item.lineDiscount * 100) / 100;
            const discountLineAmount = lineDiscount === 0 ? 0 : (Math.round(lineDiscount * lineTotalUnitPrice) / 100);

            // TODO: VAT calculations should be done on the backend (AJ)
            const lineVATAmount = (this.subsidiary === Const.SUBSIDIARIES.UNITED_KINGDOM && gender === 4) ? 0 : item.vatAmount * item.quantity;
            const lineNetValue = item.netPrice * quantity;
            const lineNetAmount = lineNetValue + lineVATAmount;
            const newLineNetAmount = lineNetValue;
            const newLineGrossAmount = newLineNetAmount + lineVATAmount;

            item.sizeClass = 'basket-pop-grid-quantity';

            if (!item.approvedByUser && quantity >= 0) {
                basket.reviewRequested = true;
            }

            // Create date object
            if (!groupedLines[requestedDeliveryDate]) {
                groupedLines[requestedDeliveryDate] = {
                    requestedDeliveryDate,
                    originalDeliveryDate,
                    items: {},
                    hasQuantityChanged: quantity !== requestedQuantity,
                    totalQuantity: 0,
                    totalRequestedQuantity: 0,
                    totalBaseAmount: 0,
                    totalDiscountedAmount: 0,
                    totalGrossAmount: 0,
                    totalVATAmount: 0,
                    totalNetAmount: 0,

                    // New
                    newTotalNetAmount: 0,
                    newTotalGrossAmount: 0,
                };
            }

            // Group by item color under date
            if (!groupedLines[requestedDeliveryDate].items[colorGroupCode]) {
                groupedLines[requestedDeliveryDate].items[colorGroupCode] = {};
                groupedLines[requestedDeliveryDate].items[colorGroupCode].itemNo = itemNo;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].itemName = itemName;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].gender = gender;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].recipeId = recipeId;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].recipeSetId = recipeSetId;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].vatPercentage = item.vatPercentage;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].approvedByUser = item.approvedByUser;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].colorCode = colorCode;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].colorName = colorName;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].brand = item.brand;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].brandName = Const.BRANDS_MAP[item.brand];
                groupedLines[requestedDeliveryDate].items[colorGroupCode].unitPrice = unitPrice;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].netPrice = lineNetValue;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].offerPrice = item.offerPrice;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].lineType = lineType;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].lineDiscount = lineDiscount;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].totalQuantity = quantity;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].totalRequestedQuantity = requestedQuantity;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].totalGrossPrice = lineNetValue;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].totalPrice = lineNetAmount;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].loadingOptions = false;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].isCustomItem = item.isCustomItem;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].isFactory = item.isFactory;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].customizationData = item.customizationData;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].widthTranslationCode = item.widthTranslationCode;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].widthId = item.widthId;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].sizes = [];
                groupedLines[requestedDeliveryDate].items[colorGroupCode].imageUrl = item.imageUrl;

                // TODO: the logic for gross and net prices is messed up in basket service so we need to clean up and start using these new values that are only used in mini-basket now (AJ)
                // Hopefully, all calculations will be done on the backend: https://asicshq.atlassian.net/browse/B2B-3270
                // New
                groupedLines[requestedDeliveryDate].items[colorGroupCode].newTotalNetAmount = newLineNetAmount;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].newTotalGrossAmount = newLineGrossAmount;

                groupedLines[requestedDeliveryDate].items[colorGroupCode].sizes.push(item);

            } else {
                if (!item.approvedByUser) {
                    groupedLines[requestedDeliveryDate].items[colorGroupCode].approvedByUser = item.approvedByUser;
                }

                groupedLines[requestedDeliveryDate].items[colorGroupCode].totalQuantity += quantity;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].totalRequestedQuantity += requestedQuantity;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].totalGrossPrice += lineNetValue;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].totalPrice += lineNetAmount;

                // New
                groupedLines[requestedDeliveryDate].items[colorGroupCode].newTotalNetAmount += newLineNetAmount;
                groupedLines[requestedDeliveryDate].items[colorGroupCode].newTotalGrossAmount += newLineGrossAmount;

                groupedLines[requestedDeliveryDate].items[colorGroupCode].sizes.push(item);
            }

            // Calculate group totals
            groupedLines[requestedDeliveryDate].totalQuantity += quantity;
            groupedLines[requestedDeliveryDate].totalRequestedQuantity += requestedQuantity;
            groupedLines[requestedDeliveryDate].totalBaseAmount += lineTotalUnitPrice;
            groupedLines[requestedDeliveryDate].totalDiscountedAmount += discountLineAmount;
            groupedLines[requestedDeliveryDate].totalGrossAmount += lineNetValue;

            // New
            groupedLines[requestedDeliveryDate].newTotalNetAmount += newLineNetAmount;
            groupedLines[requestedDeliveryDate].newTotalGrossAmount += newLineGrossAmount;

            groupedLines[requestedDeliveryDate].totalVATAmount += lineVATAmount;
            groupedLines[requestedDeliveryDate].totalNetAmount = groupedLines[requestedDeliveryDate].totalGrossAmount + groupedLines[requestedDeliveryDate].totalVATAmount;

            if (item.partnerNo) {
                basket.containsItemsSuppliedByDistributor = true;
            }
        }

        // Basket totals
        basket.totalVATAmount = 0;
        basket.totalGrossAmount = 0;
        basket.totalNetAmount = 0;
        basket.totalBaseAmount = 0;
        basket.totalQuantity = 0;
        basket.totalRequestedQuantity = 0;
        basket.newTotalNetAmount = 0;
        basket.newTotalGrossAmount = 0;
        basket.newTotalDiscountedAmount = 0;
        basket.totals = {
            netAmount: 0,
            netServiceCharge: 0,
            unitPrice: 0,
            grossServiceCharge: 0,
            discountedAmount: 0,
            vatAmount: 0,
            amountToPay: 0,
        };

        for (const date in groupedLines) {
            basket.totalVATAmount += groupedLines[date].totalVATAmount;
            basket.totalGrossAmount += groupedLines[date].totalGrossAmount;
            basket.totalBaseAmount += groupedLines[date].totalBaseAmount;
            basket.totalQuantity += groupedLines[date].totalQuantity;
            basket.totalRequestedQuantity += groupedLines[date].totalRequestedQuantity;

            // New
            basket.newTotalNetAmount += groupedLines[date].newTotalNetAmount;
            basket.newTotalGrossAmount += groupedLines[date].newTotalGrossAmount;
            basket.newTotalDiscountedAmount += groupedLines[date].totalDiscountedAmount;
        }

        const grossServiceCharge = sumBy(toArray(basket.splitByMonth), 'grossServiceCharge');
        const netServiceCharge = sumBy(toArray(basket.splitByMonth), 'netServiceCharge');

        basket.totalVATAmount += grossServiceCharge - netServiceCharge;
        basket.totalGrossPrice = basket.totalGrossAmount + grossServiceCharge;
        basket.totalNetAmount = basket.totalGrossPrice + basket.totalVATAmount;
        basket.totalAmount = basket.totalBaseAmount;

        // `basket.totals` are used in the new mini-cart
        basket.totals.unitPrice = basket.totalBaseAmount;
        basket.totals.netAmount = basket.total_amounts.net;
        basket.totals.vatAmount = basket.total_amounts.vat;
        basket.totals.amountToPay = basket.total_amounts.gross + basket.total_amounts.grossServiceCharge; // This includes service charges (if any) and VAT
        basket.totals.netServiceCharge = netServiceCharge;
        basket.totals.grossServiceCharge = grossServiceCharge;
        basket.totals.discountedAmount = basket.newTotalDiscountedAmount;

        basket.groupedLines = groupedLines;
        basket.quantityEligibleForThreeDayGuarantee = this.getQuantityEligibleForThreeDayGuarantee(basket.lines);
        basket.allLinesEligibleForThreeDayGuarantee = this.allLinesEligibleForThreeDayGuarantee(basket.lines);
        basket.isPreOrderFromTemplate = basket.isOffer ?? Boolean(basket.offerId);
        basket.isFashionContract = [Const.ORDER_TYPE.FASHION_CONTRACT, Const.ORDER_TYPE.PROMO_CONTRACT].includes(basket.orderType);

        return basket;
    },

    /**
     * Groups basket items by month and calculates totals for each month
     * @param {Object} basket
     * @param {string} userLocale
     * @returns {Object}
     */
    getGroupedLinesByMonth({ groupedLines, splitByMonth }, userLocale) {
        const itemsByMonth = {};
        const monthFormat = new Intl.DateTimeFormat(userLocale, { month: 'long' });

        for (const [date, groupedItemsByDate] of Object.entries(groupedLines)) {
            const yearMonthKey = date.substring(0, 7);

            if (!itemsByMonth[yearMonthKey]) {
                itemsByMonth[yearMonthKey] = {
                    monthName: monthFormat.format(new Date(date)),
                    totalQuantity: 0,
                    totalUnitPrice: 0,
                    totalNetAmount: 0,
                    totalGrossServiceCharge: splitByMonth[yearMonthKey].grossServiceCharge,
                    totalNetServiceCharge: splitByMonth[yearMonthKey].netServiceCharge,
                    totalDiscountedAmount: 0,
                    itemsByDate: {},
                };
            }

            itemsByMonth[yearMonthKey].totalQuantity += groupedItemsByDate.totalQuantity;
            itemsByMonth[yearMonthKey].totalUnitPrice += groupedItemsByDate.totalBaseAmount;
            itemsByMonth[yearMonthKey].totalNetAmount += groupedItemsByDate.newTotalNetAmount;
            itemsByMonth[yearMonthKey].totalDiscountedAmount += groupedItemsByDate.totalDiscountedAmount;
            itemsByMonth[yearMonthKey].itemsByDate[date] = groupedItemsByDate;
        }

        return itemsByMonth;
    },

    /**
     * Groups basket items by month and calculates totals for each month. Only used in pre-order from template basket
     * @param {Object} groupedLines
     * @param {string} userLocale
     * @returns {Object}
     */
    getPreOrderFromTemplateGroupedLinesByMonth(groupedLines, userLocale) {
        const itemsByMonth = {};
        const monthYearFormat = new Intl.DateTimeFormat(userLocale, { month: 'long', year: 'numeric' });

        for (const [rdd, groupedItemsByDate] of Object.entries(groupedLines)) {
            const date = this.resolveRequestedDeliveryDateForPreOrderTemplate(rdd);
            const yearMonthKey = date.substring(0, 7);
            const { year, month } = toDateParts(yearMonthKey);

            if (!itemsByMonth[yearMonthKey]) {
                itemsByMonth[yearMonthKey] = {
                    monthYear: monthYearFormat.format(new Date(year, month)),
                    totalQuantity: 0,
                    totalNetAmount: 0,
                    itemsByDate: {},
                };
            }

            itemsByMonth[yearMonthKey].totalQuantity += groupedItemsByDate.totalQuantity;
            itemsByMonth[yearMonthKey].totalNetAmount += groupedItemsByDate.newTotalNetAmount; // TODO: rename to totalNetAmount when basket.service.js is cleaned up
            itemsByMonth[yearMonthKey].itemsByDate[date] = groupedItemsByDate;
        }

        return itemsByMonth;
    },

    resolveRequestedDeliveryDateForPreOrderTemplate(date) {
        const { day } = toDateParts(date);

        return day >= 17 ? addMonth(new Date(date)).toISOString().split('T')[0] : date;
    },

    /**
     * Returns matching partner
     * @param {Object} basket
     * @param {string} deliveryDate
     * @param {string} itemNo
     * @param {string} colorCode
     * @param {Array} partners
     * @returns {?Object}
     */
    getMatchingPartner(basket, { deliveryDate, itemNo, colorCode, partners }) {
        const matchingLine = basket.lines.find(line => line.requestedDeliveryDate === deliveryDate && line.itemNo === itemNo && line.colorCode === colorCode) || null;

        return matchingLine ? partners.find(partner => partner.no === matchingLine.partnerNo) : null;
    },

    getQuantityEligibleForThreeDayGuarantee(basketLines) {
        return basketLines.filter(({ eligibleForThreeDayGuarantee }) => eligibleForThreeDayGuarantee).reduce((total, { quantity }) => total + quantity, 0);
    },

    allLinesEligibleForThreeDayGuarantee(basketLines) {
        return basketLines.every(({ eligibleForThreeDayGuarantee }) => eligibleForThreeDayGuarantee);
    },

    getSapOrderTypeFromOrderType(orderType) {
        switch (orderType) {
            case Const.ORDER_TYPE.CUSTOMIZED_ORDER:
                return Const.SAP_ORDER_TYPE.CUSTOM_ORDER;
            case Const.ORDER_TYPE.FASHION_CONTRACT:
                return Const.SAP_ORDER_TYPE.FASHION_CONTRACT;
            case Const.ORDER_TYPE.PROMO_CONTRACT:
                return Const.SAP_ORDER_TYPE.PROMO_CONTRACT;
            default:
                return Const.SAP_ORDER_TYPE.SALES_ORDER;
        }
    },

    getOrderTypeFromSapOrderType(orderType) {
        switch (orderType) {
            case Const.SAP_ORDER_TYPE.CUSTOM_ORDER:
                return Const.ORDER_TYPE.CUSTOMIZED_ORDER;
            case Const.SAP_ORDER_TYPE.FASHION_CONTRACT:
                return Const.ORDER_TYPE.FASHION_CONTRACT;
            case Const.SAP_ORDER_TYPE.PROMO_CONTRACT:
                return Const.ORDER_TYPE.PROMO_CONTRACT;
            default:
                return Const.ORDER_TYPE.SALES_ORDER;
        }
    },
};

export function basketIsNonEditableFOC(basket, userType) {
    // checking both properties, since we receive different properties in different resource, which needs to be refactored.
    const focStatus = basket.focStatus || basket.foc_status;

    if (!focStatus) {
        return false;
    }

    const editableStates = [Const.FOC_STATUS.PENDING];

    if (userType === Const.ACCOUNT_TYPE_SALES_REP) {
        editableStates.push(Const.FOC_STATUS.REVIEW);
    }

    return !editableStates.includes(focStatus);
}

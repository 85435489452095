import api from '@/api/api.js';

export function getAddressItemCollectionForSearch(payload) {
    const { customerNo, addressNo, season, query, partnerNo } = payload;

    return api.get(
        `itemCollection/getAddressItemCollectionForSearch/${customerNo}/${addressNo}/${season}`,
        {
            params: {
                query,
                partnerNo,
            },
        },
    );
}

export function getFashionContractSeasons(customerNo) {
    return api.get(`customers/${customerNo}/fashion-contract-seasons`);
}

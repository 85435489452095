import AbstractRouterView from '@/components/layout/AbstractRouterView.vue';
import store from '@/store/store.js';
import { Const } from '@/utils/constants.js';

export default [
    {
        path: 'employees',
        component: AbstractRouterView,
        meta: {
            authorized: () => store.state.user._type === Const.ACCOUNT_TYPE_ADMIN,
        },
        children: [
            {
                path: '',
                component: () => import('@/modules/employees/Employees.vue'),
            },
            {
                path: 'roles',
                component: AbstractRouterView,
                children: [
                    {
                        path: '',
                        component: () => import('@/modules/roles/Roles.vue'),
                    },
                    {
                        path: 'new',
                        component: () => import('@/modules/roles/Role.vue'),
                        props: {
                            action: 'create',
                        },
                    },
                    {
                        path: ':id',
                        component: () => import('@/modules/roles/Role.vue'),
                        props: route => ({ id: route.params.id, action: 'update' }),
                    },
                ],
            },
            {
                path: 'users',
                component: AbstractRouterView,
                children: [
                    {
                        path: '',
                        component: () => import('@/modules/employees/Users.vue'),
                    },
                    {
                        path: ':customerNo/accounts',
                        component: () => import('@/modules/employees/User.vue'),
                        props: route => ({ customerNo: route.params.customerNo, username: route.query.username }),
                    },
                    {
                        path: ':customerNo/accounts/new',
                        component: () => import('@/modules/employees/User.vue'),
                        props: true,
                    },
                ],
            },
            {
                path: 'teams',
                component: AbstractRouterView,
                meta: {
                    authorized: () => store.state.subsidiary.isTeamsManagementEnabled,
                },
                children: [
                    {
                        path: '',
                        component: () => import('@/modules/employees/teams/Teams.vue'),
                    },
                    {
                        path: ':teamId',
                        component: () => import('@/modules/employees/teams/Team.vue'),
                        props: route => ({ teamId: route.params.teamId }),
                    },
                    {
                        path: 'new',
                        component: () => import('@/modules/employees/teams/Team.vue'),
                        props: true,
                    },
                ],
            },
            {
                path: 'user-migration',
                component: () => import('@/modules/employees/UserMigration.vue'),
            },
        ],
    },
    {
        path: 'settings',
        component: AbstractRouterView,
        meta: {
            authorized: () => Const.ACCOUNT_TYPE_ADMIN === store.state.user._type,
        },
        children: [
            {
                path: 'homepage',
                component: () => import('@/modules/admin/settings/HomePageSettings.vue'),
            },
            {
                path: 'slideshow',
                component: () => import('@/modules/admin/settings/slideshow-manager/SlideshowManager.vue'),
            },
            {
                path: 'translations',
                component: () => import('@/modules/admin/settings/translations/SettingsTranslations.vue'),
            },
            {
                path: 'tth-management',
                component: () => import('@/modules/admin/settings/discount-manager/DiscountManager.vue'),
            },
            {
                path: 'product-manager/descriptions',
                component: () => import('@/modules/product-manager/description/DescriptionManager.vue'),
            },
            {
                path: 'product-manager/collection',
                component: () => import('@/modules/product-manager/collection/ProductManager.vue'),
            },
            {
                path: 'pricing-group-discounts',
                component: () => import('@/modules/admin/settings/pricing-group-discounts/PricingGroupDiscounts.vue'),
            },
            {
                path: 'release-notes',
                component: () => import('@/modules/release-notes/ReleaseNotes.vue'),
            },
            {
                path: 'maximum-management',
                component: () => import('@/modules/admin/settings/maximum-management/MaximumManagement.vue'),
                props: true,
                children: [
                    {
                        path: 'availability',
                        component: () => import('@/modules/admin/settings/maximum-management/MaximumAvailability.vue'),
                    },
                    {
                        path: 'order-quantity',
                        component: () => import('@/modules/admin/settings/maximum-management/MaximumOrderQuantity.vue'),
                    },
                    {
                        path: 'order-days',
                        component: () => import('@/modules/admin/settings/maximum-management/MaximumOrderDays.vue'),
                    },
                ],
            },
            {
                path: 'preorder-template',
                component: () => import('@/modules/admin/settings/preorder-template-metadata/PreorderTemplateMetadata.vue'),
                props: true,
                children: [
                    {
                        path: 'metadata',
                        component: () => import('@/modules/admin/settings/preorder-template-metadata/SettingsProductMetadata.vue'),
                    },
                    {
                        path: 'contract-validity',
                        component: () => import('@/modules/admin/settings/preorder-template-metadata/SettingsContractValidity.vue'),
                    },
                ],
            },
            {
                path: 'preorder-deadlines',
                component: () => import('@/modules/admin/settings/SettingsPreorderDeadlines.vue'),
            },
            {
                path: 'digital-assets',
                component: () => import('@/modules/admin/settings/digital-assets/SettingsDigitalAssets.vue'),
            },
            {
                path: 'basket-failed-reports/recipients',
                component: () => import('@/modules/admin/basket-failed-reports/FailedBasketRecipients.vue'),
            },
            {
                path: 'dispute-root-causes',
                component: () => import('@/modules/disputes/DisputeRootCauseManagement.vue'),
            },
            {
                path: 'max-discounts',
                component: () => import('@/modules/discounts/DiscountMaintenance.vue'),
            },
            {
                path: 'seasons/blocks/:type',
                component: () => import('@/modules/admin/settings/seasons/SeasonManager.vue'),
                props: true,
            },
        ],
    },
];

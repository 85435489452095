<template>
    <ModalDialog
        :closable="false"
        size="small"
    >
        <template #header-title>
            {{ $t('mfa.setupMfa') }}
        </template>

        <template #body>
            <div class="mb-4">
                {{ $t('mfa.setupMfaDescription') }}
            </div>
            <UserMfaEmail
                :username="props.username"
                :mfa-email="props.mfaEmail"
                :mfa-email-required="true"
                :autofocus="true"
                :show-success-snackbar-on-updated="false"
                @updated="updateMfaEmail"
            />
        </template>
    </ModalDialog>
</template>

<script setup>

import ModalDialog from '@/components/dialogs/ModalDialog.vue';
import UserMfaEmail from '@/components/user-mfa-email/UserMfaEmail.vue';
import store from '@/store/store.js';

const props = defineProps({
    username: {
        type: String,
        required: true,
    },

    mfaEmail: {
        type: String,
        default: null,
    },
});

const updateMfaEmail = mfaEmail => {
    store.commit('updateMfaEmail', mfaEmail);
};

</script>

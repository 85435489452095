export default function (number, nearest, trim) {
    const divisor = 1 / (nearest || 1);
    const decimalCount = (function (split) {
        return split.length === 2 ? split[1].length : 0;
    })(String(nearest).split('.'));

    if (divisor > 0) {
        number = (Math.round(number * divisor) / divisor).toFixed(decimalCount);
    }

    if (typeof trim === 'undefined' || trim) {
        number = parseFloat(number);
    }

    return number;
}

import api, { createTransformingApiInstance } from '@/api/api.js';

const transformingApi = createTransformingApiInstance();

export function getOffers(config) {
    return api.get('offers', config);
}

export function getOffersList() {
    return transformingApi.get('offers/list');
}

export function createOffer(payload) {
    return api.post('offer/upload', payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
    });
}

export function updateOffer(payload, id) {
    return api.post(`offer/update/${id}`, payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
    });
}

export function updateOfferStatus(payload, id) {
    return api.put(`offer/${id}/status`, payload);
}

export function blockUnblockOffer(payload, id) {
    return api.put(`offer/${id}/block`, payload);
}

export function findOrCreateBasketOffer(customerNo, addressNo, offerId) {
    return api.get(`basket/find-or-create-offer-basket/${customerNo}/${addressNo}/${offerId}`);
}

export function getHistoricOffer(unsplitBasketNo) {
    return api.get(`offers/historicOffer/${unsplitBasketNo}`);
}

export function hideOffer(customerNo, addressNo, offerId) {
    return api.post('offer/addHiddenOffer', {
        customerNo,
        addressNo,
        offerId,
    });
}

export function showOffer(customerNo, addressNo, offerId) {
    return api.post('offer/removeHiddenOffer', {
        customerNo,
        addressNo,
        offerId,
    });
}

export function notifyCustomer(offerId) {
    return api.post(`offer/${offerId}/notify-customer`);
}

export function downloadPreorderTemplateBaskets(params) {
    return api.get('offers/download-baskets', { params, responseType: 'blob' });
}

export function copyPreorderTemplateBasket(id, payload) {
    return transformingApi.post(`preorder-templates/baskets/${id}/copy`, payload);
}

export function duplicatePreorderTemplateBasket(id, payload) {
    return transformingApi.post(`preorder-templates/baskets/${id}/duplicate`, payload);
}

export function getCustomersForPreorderTemplate(id) {
    return transformingApi.get(`preorder-templates/${id}/customers`);
}

<template>
    <div
        class="flex flex-col max-w-max w-full bg-grey-lightest border-r border-grey-light"
        :class="{'min-w-[25rem]': expanded}"
    >
        <slot
            name="context-actions"
            :expanded="expanded"
        />

        <div class="flex flex-col">
            <router-link
                v-for="{title, path, icon} in menuItems"
                :key="title"
                class="flex items-center gap-6 h-[4rem] px-8 focus:text-primary hover:text-secondary transition-[background-color] duration-300 ease-in sidebar-menu-item"
                :class="{'justify-between': expanded}"
                :to="path"
                active-class="sidebar-menu-item--selected"
            >
                <span
                    class="font-bold uppercase w-full"
                    :class="{'hidden': !expanded}"
                >
                    {{ $t(title) }}
                </span>
                <i :class="[icon, {'fa-lg': !expanded}]" />
            </router-link>
        </div>

        <div class="flex flex-col w-full mt-auto border-t border-grey-light">
            <ButtonPlain
                class="w-full h-[4rem] px-8 py-4"
                @click.native="toggleSidebar()"
            >
                <i
                    class="fa fa-lg ml-auto"
                    :class="expanded ? 'fa-angle-double-left' : 'fa-angle-double-right'"
                />
            </ButtonPlain>
        </div>
    </div>
</template>

<script>
import ButtonPlain from '@/components/button/ButtonPlain.vue';

export default {
    name: 'SidebarMenu',

    components: {
        ButtonPlain,
    },

    props: {
        id: {
            type: String,
            required: true,
        },

        menuItems: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            expanded: true,
            localStorageKey: `ASICS_B2B.SIDEBAR_EXPANDED_${this.id.toUpperCase()}`,
            mediaQueryList: null,
        };
    },

    mounted() {
        const sidebarExpandedState = localStorage.getItem(this.localStorageKey);

        if (sidebarExpandedState) {
            this.toggleSidebar(sidebarExpandedState === 'true');
        }

        this.mediaQueryList = window.matchMedia('(min-width: 64rem)');

        this.mediaQueryList.addEventListener('change', event => this.handleSizeChange(event));
    },

    beforeDestroy() {
        this.mediaQueryList.removeEventListener('change', this.handleSizeChange);
    },

    methods: {
        toggleSidebar(value) {
            this.expanded = value == null ? !this.expanded : value;

            localStorage.setItem(this.localStorageKey, this.expanded);
        },

        handleSizeChange(event) {
            this.toggleSidebar(event.matches); // matches when the viewport width is at least 64rem
        },
    },
};
</script>

<style lang="scss" scoped>
@use '~@variables' as variables;

.sidebar-menu-item {
    &::before {
        content: '';
        flex: none;
        height: 100%;
        width: 0.5rem;
        margin-left: -2rem;
    }

    &.sidebar-menu-item--selected {
        background-color: variables.$asics-grey-light;
        padding-left: 0.5rem;

        &::before {
            margin-left: -0.5rem;
            background-color: variables.$asics-blue;
        }
    }

    &:not(.sidebar-menu-item--selected):hover {
        transition: background-color .2s;
        background-color: variables.$asics-grey-light;
    }
}
</style>

/**
 * Formats numbers as currency
 * @param {string|number} value
 * @param {string} localeTag
 * @param {string} currency
 * @return {string|*}
 */
export default function localisedCurrencyFormat(value, localeTag = 'en-US', currency = 'USD') {

    switch (currency) {
        case 'COP':
            localeTag = 'es-CO';
            break;
        case 'CLP':
            localeTag = 'es-CL';
            break;
    }

    const normalisedLocaleTag = localeTag.includes('_') ? localeTag.replace('_', '-') : localeTag; // TODO: remove once back-end starts using standards compliant locales
    const number = Number(value);

    if (Number.isNaN(number)) {
        return value;
    }

    return number.toLocaleString(normalisedLocaleTag, { style: 'currency', currency });
}

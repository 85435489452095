import { transformToSnakeCase } from '@/api/transformers.js';
import { unique } from '@/utils/collection.js';
import { debounce, snakeToKebabCase } from '@/utils/generic.js';
import { orderBy } from 'lodash';
import FuzzySearch from '@/components/search/fuzzy-search.js';
import api, { createCustomApiInstance } from '@/api/api.js';

const customApiInstance = createCustomApiInstance({}, {
    transformRequest: true,
    transformResponse: true,
    wrappedResponse: true,
});

let abortController;

const mixin = {
    data() {
        return {
            retainSearchQueryInput: true,
            searchQuery: '',
            searchHistory: [],
            searchHistoryMaxLen: 2,
            showSearchSuggestions: false,
            loading: true,
            loadOnStart: true,
            fields: [],
            perPage: 25,
            pageSizeOptions: [10, 25, 50, 100],
            paginationPath: 'pagination',
            css: {
                table: {
                    tableClass: 'asics-table',
                    sortableIcon: 'fa fa-sort',
                    ascendingIcon: 'fa fa-sort-up',
                    descendingIcon: 'fa fa-sort-down',
                },
                pagination: {
                    wrapperClass: 'asics-pagination',
                    activeClass: 'active',
                    disabledClass: 'disabled',
                    pageClass: 'page-item',
                    linkClass: 'page-link',
                    icons: {
                        first: 'fa fa-angle-double-left',
                        prev: 'fa fa-angle-left',
                        next: 'fa fa-angle-right',
                        last: 'fa fa-angle-double-right',
                    },
                },
            },
            apiMode: false,
            apiTransform: false,
            data: null, // contains all records, null when no data has been loaded
            filteredData: [], // contains filtered records
            fuzzy: null,
            tableCacheName: '', // string used as basis for data and search cache key
        };
    },

    computed: {
        noDataMessage() {
            return this.$t(this.loading ? 'general.tableLoadingData' : 'general.tableFilterNoResults');
        },

        tableSubsidiary() {
            return this.$store.getters.getSubsidiary;
        },

        tableUsername() {
            return this.$store.getters.username;
        },

        cacheKey() {
            return (this.tableCacheName && this.tableSubsidiary && this.tableUsername)
                ? snakeToKebabCase(`${this.tableCacheName}.${this.tableSubsidiary}.${this.tableUsername}`)
                : '';
        },

        searchCacheKey() {
            return (this.tableCacheName && this.tableSubsidiary && this.tableUsername)
                ? snakeToKebabCase(`${this.tableCacheName}.${this.tableSubsidiary}.${this.tableUsername}.search-history`)
                : '';
        },

        pageSizeCacheKey() {
            return (this.tableCacheName && this.tableSubsidiary && this.tableUsername)
                ? snakeToKebabCase(`${this.tableCacheName}.${this.tableSubsidiary}.${this.tableUsername}.page-size`)
                : '';
        },

        sortOrderCacheKey() {
            return (this.tableCacheName && this.tableSubsidiary && this.tableUsername)
                ? snakeToKebabCase(`${this.tableCacheName}.${this.tableSubsidiary}.${this.tableUsername}.sort-order`)
                : '';
        },

        appendParams() {
            if (!this.apiMode || !this.searchQuery) {
                return {};
            }

            return {
                search: this.searchQuery,
            };
        },
    },

    methods: {
        saveToStorage(key, payload, storage = localStorage) {
            if (!key) {
                return;
            }

            try {
                storage.setItem(key, JSON.stringify(payload));
            } catch (error) {
                if (error.name === 'QuotaExceededError') { // Browsers have a limit of ~ 5MB per local and session storage
                    storage.removeItem(key);
                }
            }
        },

        getFromStorage(key, fallback, storage = localStorage) {
            if (!key) {
                return fallback;
            }

            try {
                return JSON.parse(storage.getItem(key)) ?? fallback;
            } catch (error) {
                storage.removeItem(key);

                return fallback;
            }
        },

        addToSearchHistory(term) {
            if (!this.retainSearchQueryInput) {
                return;
            }

            this.searchHistory = unique([term.trim(), ...this.searchHistory]).slice(0, this.searchHistoryMaxLen);

            this.saveToStorage(this.searchCacheKey, this.searchHistory);
        },

        onLoaded() {
            if (!this.$refs.vuetable.tableData) {
                return;
            }

            this.loading = false;
        },

        onLoading() {
            this.loading = true;
        },

        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
            this.$refs.paginationInfo.setPaginationData(paginationData);
        },

        onChangePage(page) {
            this.$refs.vuetable.changePage(page);
            this.saveToStorage(this.pageSizeCacheKey, this.perPage);
            this.$refs.vuetable.callDataManager();
        },

        dataManager(sortOrder, pagination) {
            if (!this.data) {
                return;
            }

            let local = this.filteredData;

            // sortOrder can be empty, so we have to check for that as well
            if (sortOrder.length > 0) {
                local = orderBy(
                    local,
                    sortOrder[0].sortField,
                    sortOrder[0].direction,
                );
            }

            pagination = this.$refs.vuetable.makePagination(
                local.length,
                this.perPage,
            );

            const from = pagination.from - 1;
            const to = from + this.perPage;

            return {
                pagination,
                data: local.slice(from, to),
            };
        },

        onFilterSet() {
            const searchQuery = String(this.searchQuery).trim();

            this.addToSearchHistory(searchQuery); // If last search term is blank, add blank

            if (this.apiMode) {
                this.$refs.vuetable.refresh();

                return;
            }

            if (searchQuery && !this.apiMode) {
                this.sortOrder = [{ field: 'itemMatch', direction: 'desc' }];

                this.saveToStorage(this.sortOrderCacheKey, this.sortOrder, sessionStorage);
            }

            if (!this.data) {
                return;
            }

            let result = this.preFilter(this.data);

            if (searchQuery) {
                // Side effect (temp)
                this.fuzzy = new FuzzySearch();

                const parts = this.fuzzy.splitText(searchQuery);

                result = result.filter(row => { // Mutating side effects
                    row.itemMatch = this.filterFunc(parts, row);

                    return (row.itemMatch > 0);
                });
                this.filteredData = result;
            } else {
                this.filteredData = result; // Revert to existing sort
            }

            if (this.$refs.vuetable) {
                this.$refs.vuetable.refresh();
            }
        },

        preFilter(data) {
            return data;
        },

        filterFunc() {
            return 1;
        },

        fetchTableData(apiUrl, httpOptions) {
            const method = this.$refs.vuetable.httpMethod || 'get';

            if (abortController) {
                abortController.abort();
            }

            abortController = new AbortController();

            return (this.apiTransform ? customApiInstance : api)[method](apiUrl, { ...httpOptions, signal: abortController.signal });
        },

        /**
         * Reverts `paginationPath` data format back to snake_case to align with Vuetable's internal format in VuetablePaginationMixin.
         * Only applicable in API mode and when `apiTransform` is `true`.
         * @param {Object} data
         * @returns {Object}
         */
        transform(data) {
            return this.apiTransform ? { ...data, [this.paginationPath]: transformToSnakeCase(data[this.paginationPath]) } : data;
        },

        getObjectValue(object = {}, path, defaultValue) {
            return path.trim()
                .split('.')
                .reduce((obj, key) => {
                    return obj[key] == null
                        ? defaultValue ?? null
                        : (typeof obj[key] === 'string')
                            ? obj[key].replace(/</g, '&lt;')
                            : obj[key];
                }, object);
        },
    },

    // TODO: save and restore sort order in API mode?
    created() {
        if (this.sortOrderCacheKey) {
            this.sortOrder = this.getFromStorage(this.sortOrderCacheKey, [], sessionStorage);
        }

        if (this.searchCacheKey) {
            this.searchHistory = this.getFromStorage(this.searchCacheKey, []);

            if (this.retainSearchQueryInput && this.searchHistory.length) {
                this.searchQuery = this.searchHistory.at(0);

                if (this.searchQuery && !this.apiMode) {
                    this.sortOrder = [{ field: 'itemMatch', direction: 'desc' }]; // must be set early for dataManager

                    this.saveToStorage(this.sortOrderCacheKey, this.sortOrder, sessionStorage);
                }
            }
        }

        if (this.pageSizeCacheKey) {
            this.perPage = parseInt(this.getFromStorage(this.pageSizeCacheKey, this.perPage), 10);
        }

        if (this.cacheKey) {
            this.data = this.getFromStorage(this.cacheKey, null, sessionStorage);
        }
    },

    mounted() {
        if (this.$refs.vuetable) {
            // Overwrite original function in node_modules/vuetable-2/src/components/Vuetable.vue to escape HTML tags because VueTable uses unsafe v-html directive
            this.$refs.vuetable.getObjectValue = (object, path, defaultValue) => this.getObjectValue(object, path, defaultValue);

            // $off called in beforeDestroy hook
            this.$refs.vuetable.$on('vuetable:loading', this.onLoading);
            this.$refs.vuetable.$on('vuetable:loaded', this.onLoaded);
        }

        if (this.apiMode) {
            // Laravel standard, use meta
            this.paginationPath = 'meta';
        }

        // Call function immediately if data is available. This is usually the case when getting data from session storage.
        if (this.data) {
            this.onFilterSet();
        }

        // Each component must have its own debounce wrapper function
        this.onFilterSet = debounce(this.onFilterSet, 300);
    },

    beforeDestroy() {
        if (this.$refs.vuetable) {
            this.$refs.vuetable.$off('vuetable:loading', this.onLoading);
            this.$refs.vuetable.$off('vuetable:loaded', this.onLoaded);
        }
    },
};

export default mixin;

<template>
    <div
        v-if="state.dataLoaded"
        class="flex flex-col items-center sm:flex-row gap-4 sm:gap-8 sm:justify-between"
    >
        <template v-for="transitionState in state.transitionStates">
            <Button
                v-if="!transitionState.disabled"
                :key="transitionState.nextStatus"
                :text="transitionState.nextStatusText"
                :disabled="basketIsEmpty || state.transitionInProgress"
                class="w-full sm:w-auto"
                @click.native="transition(transitionState)"
            >
                <i
                    v-show="state.transitionInProgress"
                    class="fa fa-spinner fa-spin"
                />
            </Button>
            <Alert
                v-else-if="!basketIsEmpty"
                :key="transitionState.nextStatus"
                :message="transitionState.nextStatusText"
                size="small"
            />
        </template>
    </div>
</template>

<script setup>
import Alert from '@/components/alert/Alert.vue';
import Button from '@/components/button/Button.vue';
import { bugsnag } from '@/libs/bugsnag.js';
import { getBasket } from '@/modules/baskets/baskets.api.js';
import { getTransitionStates, transitionToNextState } from '@/modules/baskets/mini-basket/pre-order-template.api.js';
import router from '@/router/router.js';
import store from '@/store/store.js';
import { Const } from '@/utils/constants.js';
import { reactive } from 'vue';

const props = defineProps({
    basket: {
        type: Object,
        required: true,
    },

    basketIsEmpty: {
        type: Boolean,
        required: true,
    },
});

const state = reactive({
    transitionStates: [
        {
            nextStatus: null,
            nextStatusText: 'loading',
            disabled: true,
        },
    ],
    errorStateData: {
        nextStatus: null,
        nextStatusText: 'error',
        disabled: true,
    },
    dataLoaded: false,
    transitionInProgress: false,
});

const redirectToCheckout = async () => {
    try {
        await router.push(`/preorders/${props.basket.no}/checkout`);
    } catch (error) {
        bugsnag.notify(error);
    }
};

const transition = async ({ nextStatus }) => {

    if (nextStatus === 'checkout') {
        await redirectToCheckout();

        return;
    }

    state.transitionInProgress = true;
    
    try {
        await transitionToNextState(props.basket.no, { nextStatus });
        await updatePreOrderFromTemplateBasket();
        await fetchStates();
    } catch (error) {
        state.transitionStates = [state.errorStateData];

        bugsnag.notify(error);
    } finally {
        state.transitionInProgress = false;
    }
};

const updatePreOrderFromTemplateBasket = async () => {
    try {
        const { data } = await getBasket(props.basket.no);

        store.commit('updateOffer', data);

        if ([Const.OFFER_STATUS_MULTIPLEXING, Const.OFFER_STATUS_COMPLETE].includes(data.offerStatus)) {
            return router.push(`/offers/${props.basket.no}`);
        }
    } catch (error) {
        bugsnag.notify(error);
    }
};

const fetchStates = async () => {
    try {
        const { data } = await getTransitionStates(props.basket.no);

        state.transitionStates = data;
        state.dataLoaded = true;
    } catch (error) {
        bugsnag.notify(error);
    }
};

fetchStates();

</script>

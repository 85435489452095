import api, { createTransformingApiInstance } from '@/api/api.js';
import { Const } from '@/utils/constants.js';

export function getCustomers(params) {
    return api.get('customers', { params });
}

export function getCustomerDistributionCenters(customerNo) {
    return api.get(`customers/${customerNo}/distribution-centers`);
}

export function getDistributors(customerNo) {
    return api.get(`customers/${customerNo}/distributors`);
}

export function markAsDefaultDeliveryAddress(customerNo, payload) {
    return api.patch(`customers/${customerNo}/addresses/mark-as-default-delivery-address`, payload);
}

export function getSalesRepresentatives(customerNo) {
    return api.get(`customers/${customerNo}/sales-reps`);
}

export function updateCustomerSettings(customerNo, payload) {
    return api.patch(`customers/${customerNo}/settings`, payload);
}

export function createUser(customerNo, payload) {
    const transformingApi = createTransformingApiInstance(); // Used here due to circular dependency issue

    return transformingApi.post(`customers/${customerNo}/users`, payload);
}

export function updateCustomerUser(customerNo, username, payload) {
    const transformingApi = createTransformingApiInstance(); // Used here due to circular dependency issue

    return transformingApi.patch(`customers/${customerNo}/users/${username}`, payload);
}

export function getCustomerUser(customerNo, username) {
    const transformingApi = createTransformingApiInstance(); // Used here due to circular dependency issue

    return transformingApi.get(`customers/${customerNo}/users/${username}`);
}

export function getCustomerCreditInfo(customerNo) {
    const transformingApi = createTransformingApiInstance(); // Used here due to circular dependency issue

    return transformingApi.get(`customers/${customerNo}/credit-documents`);
}

export function getCustomerLegalInfo(customerNo) {
    return api.get(`legal/get/${customerNo}`);
}

export function getCustomerContacts(customerNo) {
    return api.get(`customerCtrl/getContactsByCustomerNoForDatatable/${customerNo}`);
}

export function getCustomerServiceCharges(customerNo) {
    return api.get(`customers/${customerNo}/service-charges`);
}

export function updateCustomerServiceCharges(customerNo, payload) {
    return api.patch(`customers/${customerNo}/service-charges`, payload);
}

export function deleteCustomerServiceCharges(customerNo, serviceChargeId) {
    return api.delete(`customers/${customerNo}/service-charges/${serviceChargeId}`);
}

export function getCustomerPricingGroupDiscounts(customerNo) {
    const transformingApi = createTransformingApiInstance(); // Used here due to circular dependency issue

    return transformingApi.get(`customers/${customerNo}/pricing-group-discounts`);
}

export function updateCustomerPricingGroupDiscounts(customerNo, params) {
    const transformingApi = createTransformingApiInstance(); // Used here due to circular dependency issue

    return transformingApi.put(`customers/${customerNo}/pricing-group-discounts`, params);
}

export function getCustomerECatalogs(customerNo) {
    const transformingApi = createTransformingApiInstance(); // Used here due to circular dependency issue

    return transformingApi.get(`customers/${customerNo}/e-catalogs`);
}

export function getCustomerCurrencyCode(customerNo) {
    return api.get(`customers/${customerNo}/currency-code`);
}

export function getBillTrustSSOLink(customerNo) {
    return api.get(`customerCtrl/${customerNo}/ssoLink`);
}

export function getCustomerAddressRatings(customerNo, addressNo, params) {

    const transformingApi = createTransformingApiInstance(); // Used here due to circular dependency issue

    return transformingApi.get(`customerCtrl/getCustomerAddressRatings/${customerNo}/${addressNo}`, {
        params,
    });
}

export function downloadCustomerCreditDocument(customerNo, creditDocumentNo) {
    return api.get(`customers/${customerNo}/credit-documents/${creditDocumentNo}/download`, {
        responseType: 'blob',
        headers: {
            Accept: Const.MIME_TYPE.PDF,
        },
    });
}

export function getAccountTypes() {
    return api.get('customers/account-types');
}

export function getCustomerGroups() {
    return api.get('customers/customer-groups');
}

export function getCustomerSuggestions(params) {
    const transformingApi = createTransformingApiInstance(); // Used here due to circular dependency issue

    return transformingApi.get('suggestions/customer', {
        params,
    });
}

import basketService from '@/modules/baskets/basket.service.js';
import { Const } from '@/utils/constants.js';

export default {
    updateFilterState(state, filterState) {
        state.plp.filterState = filterState;
    },

    updateSearchState(state, newSearch) {
        state.plp.search = newSearch;
    },

    updateSortState(state, newSort) {
        state.plp.sort = newSort;
    },

    updatePlpOrderJourneyState(state, orderJourney) {
        state.plp.orderJourney = orderJourney;
    },

    updateMfaEmail(state, mfaEmail) {
        state.user.mfa_email = mfaEmail;
    },

    updateUserLocale(state, locale) {
        state.user.locale = locale;
    },

    updatePreferredSizeChart(state, size) {
        state.preferences.sizeChart = size;
    },

    updatePreferredPriceDisplay(state, priceDisplay) {
        state.preferences.priceDisplay = priceDisplay;
    },

    updateBasket(state, newBasket) {
        state.basket = basketService.transformBasket(newBasket);
    },

    updateCustomizedBasket(state, newBasket) {
        state.customizedBasket = basketService.transformBasket(newBasket);
    },

    updateOffer(state, newOffer) {
        state.offer = newOffer;
    },

    updateCustomerDeliveryAddresses(state, { customerNo, addresses }) {
        state.customerDeliveryAddresses = {
            ...state.customerDeliveryAddresses,
            [customerNo]: addresses,
        };

        if (!addresses.length) {
            console.warn(`Customer ${customerNo} has no addresses. There's something wrong with customer data.`);
        }
    },

    updateCustomerDistributionCenters(state, { customerNo, distributionCenters }) {
        state.customerDistributionCenters = {
            ...state.customerDistributionCenters,
            [customerNo]: distributionCenters,
        };

        if (!distributionCenters.length) {
            console.warn(`Customer ${customerNo} has no distribution centers. There's something wrong with customer data.`);
        }
    },

    updateProductFilters(state, { filters, orderJourney }) {
        if (orderJourney === Const.ORDER_JOURNEY.AT_ONCE) {
            state.atOnceProductFilters = filters;
        } else {
            state.preOrderProductFilters = filters;
        }
    },

    setNotifications(state, notifications) {
        state.notifications = notifications;
    },

    updateNotifications(state, notification) {
        state.notifications.unshift(notification);
    },

    setNotificationRead(state, notificationId) {
        const notificationIndex = state.notifications.findIndex(({ id }) => id === notificationId);

        if (notificationIndex !== -1) {
            state.notifications[notificationIndex].read = true;
        }
    },

    updateIsLoggedOut(state, status) {
        state.isLoggedOut = status;
    },

    setBreadcrumbs(state, breadcrumbs) {
        state.breadcrumbs = breadcrumbs;
    },

    updateOrderGridClipboard(state, data) {
        state.orderGridClipboard = data;
    },

    approvedLegalAgreementCustomerNo(state, customerNo) {
        state.legal.legalApproval = {
            ...state.legal.legalApproval,
            customerNo,
        };
    },

    setApiErrors(state, errors) {
        state.apiErrors = errors;
    },

    setMobileViewport(state, value) {
        state.isMobileViewport = value;
    },

    setPlpScrollTop(state, value) {
        state.plp.scrollTop = value;
    },

    setAvailableOrderJourneys(state, value) {
        const { ORDER_JOURNEY } = Const;
        const orderJourneyMap = {
            [ORDER_JOURNEY.AT_ONCE]: {
                translationKey: 'orderJourney.atOnce',
                value: ORDER_JOURNEY.AT_ONCE,
            },
            [ORDER_JOURNEY.PRE_ORDER]: {
                translationKey: 'orderJourney.preOrder',
                value: ORDER_JOURNEY.PRE_ORDER,
            },
        };

        state.availableOrderJourneys = value.map(orderJourney => orderJourneyMap[orderJourney]);
    },

    setToastNotification(state, notification) {
        state.toastNotifications = [...state.toastNotifications, notification];
    },

    hideToastNotification(state, id) {
        state.toastNotifications = state.toastNotifications.filter(notification => notification.id !== id);
    },

    setAuthUserState(state, { user, customer, subsidiary, preferences, legal }) {
        state.user = user ?? null;
        state.customer = customer ?? null;
        state.preferences = preferences ?? null;
        state.subsidiary = subsidiary ?? null;
        state.legal = legal ?? null;
    },

    setCustomizationConfig(state, customization) {
        state.customization = customization;
    },

    setIsProductionSap(state, value) {
        state.isProductionSap = value;
    },
};

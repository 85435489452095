<template>
    <div class="flex flex-col h-screen pb-8 sm:pb-0 divide-y divide-grey overflow-auto">
        <div
            v-if="basket.totalRequestedQuantity === 0"
            class="flex items-center justify-center h-full py-12"
        >
            {{ $t('basket.isEmpty') }}
        </div>
        <template v-else>
            <MiniBasketPreOrderFromTemplateItemsGroup
                v-if="basket.isPreOrderFromTemplate "
                :format-price="formatPrice"
                :format-number="formatNumber"
                :should-show-price="shouldShowPrice"
                :basket="basket"
            />
            <MiniBasketItemsByDate
                v-for="(group, date) in basket.groupedLines"
                v-else
                :key="date"
                :date="date"
                :items="group.items"
                :basket-info="basketInfo"
                :should-show-price="shouldShowPrice"
                @removeItem="onRemoveItem"
                @removeItemsPerDate="onRemoveItemsPerDate"
            />
        </template>
    </div>
</template>

<script setup>
import { bugsnag } from '@/libs/bugsnag.js';
import { removeItemFromBasket, removeItemsPerDateFromBasket } from '@/modules/baskets/baskets.api.js';
import MiniBasketItemsByDate from '@/modules/baskets/mini-basket/MiniBasketItemsByDate.vue';
import MiniBasketPreOrderFromTemplateItemsGroup from '@/modules/baskets/mini-basket/preorder-from-template/MiniBasketPreOrderFromTemplateItemsGroup.vue';
import { computed } from 'vue';

const props = defineProps({
    basket: {
        type: Object,
        required: true,
    },

    shouldShowPrice: {
        type: Boolean,
        required: true,
    },

    formatNumber: {
        type: Function,
        required: true,
    },

    formatPrice: {
        type: Function,
        required: true,
    },

    isCustomizedOrder: {
        type: Boolean,
        required: true,
    },
});

const basketInfo = computed(() => ({
    basketNo: props.basket.no,
    orderJourney: props.basket.orderJourney,
    currencyCode: props.basket.currencyCode,
    customerDistributors: props.basket.partners ?? [],
    isCreatedByPayer: !!props.basket?.creating_payer_no,
    isCustomizedOrder: props.isCustomizedOrder,
}));

const onRemoveItem = async (requestedDeliveryDate, {
    itemNo,
    colorCode,
    lineType,
    recipeId,
}) => {
    try {
        await removeItemFromBasket(props.basket.no, itemNo, {
            requestedDeliveryDate,
            colorCode,
            lineType,
            recipeId,
        });
    } catch (error) {
        bugsnag.notify(error);
    }
};

const onRemoveItemsPerDate = async requestedDeliveryDate => {
    try {
        await removeItemsPerDateFromBasket(props.basket.no, { requestedDeliveryDate });
    } catch (error) {
        bugsnag.notify(error);
    }
};

</script>

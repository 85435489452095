import api, { createTransformingApiInstance } from '@/api/api.js';

export function getCustomerSeasons(customerNo) {
    return api.get(`customers/${customerNo}/seasons?sort=desc&limit=4`);
}

export function getCustomerAddresses(customerNo, params) {
    return api.get(`customers/${customerNo}/addresses`, { params });
}

export function getCustomerAddress(customerNo, addressNo) {
    // TODO: move to top of the file after login Vue app is integrated into the main one. Now there's circular dependency between imports of api.js
    const transformingApi = createTransformingApiInstance();

    return transformingApi.get(`customers/${customerNo}/addresses/${addressNo}`);
}

export function getOrderJourneys(customerNo) {
    return api.get(`customers/${customerNo}/order-journeys`);
}

import api from '@/api/api.js';

export function getPosts(params) {
    return api.get('blog', { params });
}

export function getHomepagePosts() {
    return api.get('blog/homepage');
}

export function getPost(id) {
    return api.get(`blog/${id}`);
}

export function downloadAttachment(postId, attachmentId) {
    return api.get(`blog/${postId}/attachments/${attachmentId}`, {
        responseType: 'blob',
    });
}

export function acknowledgeNewsAlert(id) {
    return api.post(`news-alerts/${id}/acknowledgements`);
}

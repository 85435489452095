<template>
    <div
        class="flex items-baseline border rounded p-1 gap-4 leading-snug"
        :class="{
            'bg-secondary-lightest text-primary border-secondary': kind === 'info',
            'bg-yellow-lightest text-primary border-yellow': kind === 'warning',
            'bg-red-lightest text-red-dark border-red': kind === 'danger',
            'text-sm': size === 'small',
            'text-lg': size === 'large',
            'p-1': padding === 'small',
            'p-4': padding === 'normal',
        }"
    >
        <i
            :class="['fa', {
                'fa-circle-info text-secondary': kind === 'info',
                'fa-circle-exclamation text-yellow': kind === 'warning',
                'fa-triangle-exclamation text-red': kind === 'danger',
                'fa-sm': size === 'small',
                'fa-lg': size === 'normal',
                'fa-xl': size === 'large',
            }]"
        />
        <div class="flex items-center w-full leading-snug">
            <span
                v-if="message"
                class="text-left"
            >
                {{ message }}
            </span>
            <slot />
        </div>
        <ButtonPlain
            v-if="canClose"
            @click.native="close"
        >
            <i class="fa fa-close fa-lg" />
        </ButtonPlain>
    </div>
</template>

<script>
import ButtonPlain from '@/components/button/ButtonPlain.vue';

export default {
    name: 'Alert',

    components: {
        ButtonPlain,
    },

    props: {
        kind: {
            type: String,
            default: 'info',
            validator(value) {
                const isValid = ['info', 'warning', 'danger'].some(kind => kind === value);

                if (!isValid) {
                    console.warn(`Invalid kind of alert "${value}"`);
                }

                return isValid;
            },
        },

        size: {
            type: String,
            default: 'normal',
            validator(value) {
                const isValid = ['small', 'normal', 'large'].some(kind => kind === value);

                if (!isValid) {
                    console.warn(`Invalid size for alert "${value}"`);
                }

                return isValid;
            },
        },

        padding: {
            type: String,
            default: 'normal',
            validator(value) {
                const isValid = ['small', 'normal'].some(kind => kind === value);

                if (!isValid) {
                    console.warn(`Invalid padding for alert "${value}"`);
                }

                return isValid;
            },
        },

        message: {
            type: String,
            default: '',
        },

        canClose: {
            type: Boolean,
            default: false,
        },
    },

    emits: [
        'close',
    ],

    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>

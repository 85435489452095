<template>
    <div class="flex flex-col justify-between h-full overflow-hidden divide-y divide-grey">
        <div class="h-full overflow-auto">
            <table class="w-full">
                <thead>
                    <tr class="bg-green-lightest">
                        <th class="p-2 pl-6 text-left">
                            {{ $t('general.labelMonth') }}
                        </th>
                        <th class="p-2 text-left">
                            {{ $t('orderDetails.quantity') }}
                        </th>
                        <th
                            v-if="shouldShowPrice"
                            class="p-2 text-left"
                        >
                            {{ $t('general.labelTotals') }}
                        </th>
                        <th class="p-2" />
                    </tr>
                </thead>
                <tbody class="divide-y divide-grey-light">
                    <template v-for="(monthSummary, yearMonth) in itemsByMonth">
                        <tr
                            :key="yearMonth"
                            :class="{'bg-grey-lightest': state.expanded[yearMonth]}"
                        >
                            <td class="p-2 pl-6">
                                {{ monthSummary.monthYear }}
                            </td>
                            <td class="p-2">
                                {{ formatNumber(monthSummary.totalQuantity) }}
                            </td>
                            <td
                                v-if="shouldShowPrice"
                                class="p-2"
                            >
                                {{ formatPrice(monthSummary.totalNetAmount) }}
                            </td>
                            <td class="p-2">
                                <ButtonPlain
                                    kind="neutral"
                                    @click.native="toggleExpanded(yearMonth)"
                                >
                                    <i :class="['fa', state.expanded[yearMonth] ? 'fa-minus' : 'fa-plus']" />
                                </ButtonPlain>
                            </td>
                        </tr>
                        <MiniBasketPreOrderFromTemplateMonthItems
                            :key="`${yearMonth}-content`"
                            :basket-info="basketInfo"
                            :expanded="state.expanded[yearMonth]"
                            :should-show-price="shouldShowPrice"
                            :items-by-date="monthSummary.itemsByDate"
                            :year-month="yearMonth"
                        />
                    </template>
                </tbody>
            </table>
        </div>
        <BasketSummaryTotals
            :should-pay-in-advance="false"
            :total-quantity-by-asics="totalQuantitySuppliedByAsics"
            :total-quantity-by-distributors="0"
            :net-amount="basket.totals.netAmount"
            :net-service-charge="basket.totals.netServiceCharge"
            :gross-service-charge="basket.totals.grossServiceCharge"
            :vat-amount="basket.totals.vatAmount"
            :amount-to-pay="basket.totals.amountToPay"
            :should-show-price="shouldShowPrice"
            :format-units="formatUnits"
            :format-price="formatPrice"
        />
    </div>
</template>

<script setup>
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import { useI18n } from '@/composables/use-i18n.js';
import basketService from '@/modules/baskets/basket.service.js';
import BasketSummaryTotals from '@/modules/baskets/basket/basket-summary/BasketSummaryTotals.vue';
import MiniBasketPreOrderFromTemplateMonthItems from '@/modules/baskets/mini-basket/preorder-from-template/MiniBasketPreOrderFromTemplateMonthItems.vue';
import store from '@/store/store.js';
import { computed, reactive, watch } from 'vue';

const props = defineProps({
    basket: {
        type: Object,
        required: true,
    },

    shouldShowPrice: {
        type: Boolean,
        required: true,
    },

    formatNumber: {
        type: Function,
        required: true,
    },

    formatPrice: {
        type: Function,
        required: true,
    },
});

const { t } = useI18n();

const state = reactive({
    expanded: Object.fromEntries(Object.keys(props.basket.groupedLines).map(date => [basketService.resolveRequestedDeliveryDateForPreOrderTemplate(date).substring(0, 7), false])),
});

const basketInfo = computed(() => ({
    basketNo: props.basket.no,
    orderJourney: props.basket.orderJourney,
    currencyCode: props.basket.currencyCode,
    customerDistributors: props.basket.partners ?? [],
    isCustomizedOrder: props.isCustomizedOrder,
}));

const itemsByMonth = computed(() => basketService.getPreOrderFromTemplateGroupedLinesByMonth(props.basket.groupedLines, store.getters.userLocale));
const totalQuantitySuppliedByAsics = computed(() => props.basket.lines.reduce((accumulator, { partnerNo, quantity }) => !partnerNo ? accumulator + quantity : accumulator, 0));
const formatUnits = totalQuantity => `${props.formatNumber(totalQuantity)} ${totalQuantity === 1 ? t('basket.unit') : t('basket.units')}`;
const toggleExpanded = yearMonth => {
    state.expanded[yearMonth] = !state.expanded[yearMonth];
};

watch(() => props.basket.groupedLines, () => {
    state.expanded = Object.fromEntries(Object.keys(props.basket.groupedLines).map(date => [basketService.resolveRequestedDeliveryDateForPreOrderTemplate(date).substring(0, 7), false]));
});
</script>

<template>
    <DropDownMenu>
        <template #title>
            {{ $t('adminMainMenu.employees') }}
        </template>
        <template #dropdown>
            <li>
                <router-link to="/employees">
                    {{ $t('adminMainMenu.listEmployees') }}
                </router-link>
            </li>
            <li>
                <router-link to="/employees/users">
                    {{ $t('adminMainMenu.listUsers') }}
                </router-link>
            </li>
            <li v-if="isAdmin && isTeamsManagementEnabled">
                <router-link to="/employees/teams">
                    {{ $t('adminMainMenu.teams') }}
                </router-link>
            </li>
            <li class="divider" />
            <li>
                <router-link to="/employees/roles">
                    {{ $t('adminMainMenu.listRoles') }}
                </router-link>
            </li>
            <li>
                <router-link to="/employees/roles/new">
                    {{ $t('adminMainMenu.createRole') }}
                </router-link>
            </li>
            <li class="divider" />
            <li v-if="isAdmin">
                <router-link to="/employees/user-migration">
                    {{ $t('adminMainMenu.userUpload') }}
                </router-link>
            </li>
        </template>
    </DropDownMenu>
</template>

<script>
import DropDownMenu from '@/components/menu/DropDownMenu.vue';
import { Const } from '@/utils/constants';

export default {
    name: 'EmployeesDropdownMenu',

    components: {
        DropDownMenu,
    },

    computed: {
        isAdmin() {
            return this.$store.getters.getUserType === Const.ACCOUNT_TYPE_ADMIN;
        },

        isTeamsManagementEnabled() {
            return this.$store.state.subsidiary.isTeamsManagementEnabled;
        },
    },
};
</script>

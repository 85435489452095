import api, { createTransformingApiInstance } from '@/api/api.js';

export function getEmployees(params) {
    return api.get('sales-reps', { params });
}

export function getUsers(params) {
    const transformingApi = createTransformingApiInstance();

    return transformingApi.get('users', { params });
}

export function createUser(payload) {
    const transformingApi = createTransformingApiInstance();

    return transformingApi.post('users', payload);
}

export function updateUser(username, payload) {
    const transformingApi = createTransformingApiInstance();

    return transformingApi.patch(`users/${username}`, payload);
}

export function updateMfaEmail(username, mfaEmail) {
    const transformingApi = createTransformingApiInstance();

    return transformingApi.patch(`users/${username}/mfa-email`, { mfaEmail });
}

export function deleteUser(username) {
    return api.delete(`users/${username}`);
}

export function createAdminUser(payload) {
    const transformingApi = createTransformingApiInstance();

    return transformingApi.post('admin-users', payload);
}

export function updateAdminUser(username, payload) {
    const transformingApi = createTransformingApiInstance();

    return transformingApi.patch(`admin-users/${username}`, payload);
}

export function deleteAdminUser(username) {
    return api.delete(`admin-users/${username}`);
}

export function impersonateUser(username) {
    return api.post(`users/${username}/impersonation`, null);
}

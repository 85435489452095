<template>
    <button
        type="button"
        class="flex gap-4 focus:outline-none justify-center place-items-center h-[3.8rem] px-12 py-4 rounded-full font-bold capitalize whitespace-nowrap touch-manipulation print:hidden"
        :class="[
            {'bg-primary hover:bg-primary-dark focus-visible:bg-primary-dark text-white leading-[2rem]': kind === 'primary'},
            {'bg-transparent hover:bg-primary/10 focus-visible:bg-primary/10 text-primary leading-[1.6rem] border-2 border-primary': kind === 'primary-alt'},
            {'bg-white hover:bg-primary/10 focus-visible:bg-primary/10 text-primary leading-[2rem]': kind === 'secondary'},
            {'bg-transparent hover:bg-primary/10 focus-visible:bg-primary/10 text-white leading-[1.6rem] border-2 border-white': kind === 'secondary-alt'},
            {'bg-secondary hover:bg-secondary-dark focus-visible:bg-secondary-dark text-white leading-[2rem]': kind === 'tertiary'},
            {'bg-secondary-light hover:bg-secondary-lightest--hover focus-visible:bg-secondary-lightest--hover text-primary leading-[2rem]': kind === 'tertiary-alt'},
            {'bg-red-dark hover:bg-red-darkest--hover focus-visible:bg-red-darkest--hover text-white leading-[2rem]': kind === 'danger'},
            {'disabled:opacity-50 pointer-events-none': disabled},
        ]"
        :disabled="disabled"
    >
        <slot />
        <span
            v-if="text"
            class="capitalize"
        >
            {{ text }}
        </span>
    </button>
</template>

<script>
export default {
    name: 'Button',

    props: {
        text: {
            type: String,
            default: null,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        kind: {
            type: String,
            default: 'primary',
            validator(value) {
                const isValid = ['primary', 'primary-alt', 'secondary', 'secondary-alt', 'tertiary', 'tertiary-alt', 'danger'].some(kind => kind === value);

                if (!isValid) {
                    console.warn('Invalid kind of button provided');
                }

                return isValid;
            },
        },
    },
};
</script>

<style scoped>
button {
    min-width: 10rem;
}
</style>

<template>
    <DropDownMenu
        expanded-menu-colors="bg-magenta text-white"
        dropdown-css="flex !flex-row gap-4"
    >
        <template #title>
            {{ $t('adminMainMenu.customization') }}
        </template>
        <template #dropdown>
            <li>
                <div class="font-bold py-4">
                    {{ $t('general.customProducts') }}
                </div>
                <ul class="flex flex-col gap-y-2">
                    <li>
                        <router-link
                            v-slot="{route}"
                            to="/product-customization/orders/:basketNo/product-designs"
                        >
                            <ButtonPlain
                                :text="$t('customerOrders.productDesigns')"
                                class="font-normal"
                                :disabled="state.fetchBasketInProgress.productDesigns"
                                @click.native="navigate(route, 'productDesigns')"
                            />
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/product-customization/design-systems">
                            {{ $t('adminMainMenu.customDesign') }}
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            v-slot="{route}"
                            to="/product-customization/orders/:basketNo/product-sets"
                        >
                            <ButtonPlain
                                :text="$t('customerOrders.customDesignProducts')"
                                class="font-normal"
                                :disabled="state.fetchBasketInProgress.productSets"
                                @click.native="navigate(route, 'productSets')"
                            />
                        </router-link>
                    </li>
                </ul>
            </li>
            <li>
                <router-link
                    :to="`/customers/${customerNo}/orders/new?type=customizedOrder`"
                    class="flex items-center gap-2 font-bold py-4"
                >
                    {{ $t('adminMainMenu.customOrder') }}
                    <i class="fa fa-arrow-right" />
                </router-link>
            </li>
        </template>
    </DropDownMenu>
</template>

<script setup>
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import DropDownMenu from '@/components/menu/DropDownMenu.vue';
import { bugsnag } from '@/libs/bugsnag.js';
import { getCurrentBasket } from '@/modules/baskets/baskets.api.js';
import router from '@/router/router.js';
import { Const } from '@/utils/constants.js';
import { computed, reactive } from 'vue';
import store from '@/store/store.js';

const state = reactive({
    fetchBasketInProgress: {
        productDesigns: false,
        productSets: false,
    },
});

const customerNo = computed(() => store.getters.getRealCustomerNo);

const fetchBasket = async () => {
    const currentCustomizedBasket = store.getters.customizedBasket;

    if (currentCustomizedBasket?.orderType === Const.ORDER_TYPE.CUSTOMIZED_ORDER) {
        return currentCustomizedBasket;
    }

    const { data: basket } = await getCurrentBasket({ customized: true });

    await store.dispatch('updateBasket', basket);

    return basket;
};

const navigate = async ({ path }, name) => {
    state.fetchBasketInProgress[name] = true;

    try {
        const basket = await fetchBasket();

        await router.push(path.replace(':basketNo', basket.no));
    } catch (error) {
        bugsnag.notify(error);
    } finally {
        state.fetchBasketInProgress[name] = false;
    }
};

</script>

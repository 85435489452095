<template>
    <div
        v-if="filePath"
        class="hidden md:flex items-center"
    >
        <img
            :src="filePath"
            alt=""
            class="max-w-[10rem] max-h-[3.7rem] h-auto"
        >
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    name: 'CustomerLogo',

    props: {
        customerNo: {
            type: String,
            default: null,
        },
    },

    computed: {
        ...mapState(['user']),
        ...mapState('customerLogo', [
            'filePath',
        ]),
    },

    created() {
        this.fetchCustomerLogo({ customerNo: this.customerNo ?? this.user._customerNo });
    },

    methods: {
        ...mapActions('customerLogo', ['fetchCustomerLogo']),
    },
};
</script>

/**
 * @param {Array<Object>} array
 * @param {string} key
 * @return {number}
 */
export function sumBy(array = [], key) {
    return array.reduce((sum, item) => sum + Number(item[key]), 0);
}

/**
 * @param {Array<number>} array
 * @return {number}
 */
export function sum(array = []) {
    return array.reduce((sum, value) => sum + Number(value), 0);
}

/**
 * @param {Array<Object>} array
 * @param {string} key
 * @return {Array<*>}
 */
export function pluck(array = [], key) {
    return array.map(item => item[key]);
}

/**
 * Maps array to object grouped by a given key.
 * Example:
 * Input array: [{ country: 'NL', language: 'Dutch' }, { country: 'USA', language: 'English' }]
 * Input key: 'country'
 * Result: { NL: { country': 'NL', language: 'Dutch' }, US: { country: 'USA', language: 'English' } }
 *
 * https://lodash.com/docs/4.17.15#keyBy
 *
 * @param {Array<Object>} array
 * @param {string} key
 * @return {Object}
 */
export function keyBy(array, key) {
    return (array || []).reduce((result, currentValue) => ({
        ...result,
        [currentValue[key]]: currentValue,
    }), {});
}

/**
 * @param {Object} object
 * @return {Array<*>}
 */
export function toArray(object) {
    return Object.values(object || {});
}

/**
 * Sorts an object by property value
 * @param {Object} obj
 * @param {Function} sortFunc
 * @return {Object}
 */
export function sortObject(obj, sortFunc) {
    return Object.fromEntries(Object.entries(obj).sort(sortFunc));
}

/**
 * @param {Object} object
 * @param {Function} predicate example: ([key, value]) => true
 * @return {Object}
 */
export function filterObject(object, predicate) {
    return Object.fromEntries(Object.entries(object).filter(predicate));
}

/**
 * Maps an object to a new object, transforming its keys and/or values
 * @param {Object} object
 * @param {Function} predicate example: ([key, value]) => [key, Number(value)]
 * @returns {Object}
 */
export function mapObject(object, predicate) {
    return Object.fromEntries(Object.entries(object).map(predicate));
}

/**
 * Flat maps an object to a new object, transforming its keys and/or values
 * @param {Object} object
 * @param {Function} predicate example: ([key, value]) => [[key, Number(value)], [key2, value2]]
 * @returns {Object}
 */
export function flatMapObject(object, predicate) {
    return Object.fromEntries(Object.entries(object).flatMap(predicate));
}

export function isObject(value) {
    return value != null && value.constructor.name === 'Object';
}

export function isEmptyObject(value) {
    return isObject(value) && Object.keys(value).length === 0;
}

export function isArray(value) {
    return Array.isArray(value);
}

/**
 * Creates an array of unique primitive values. If value is an object, provide `predicate` map function to map to primitive value.
 * @param {string[]|number[]|boolean[]} list
 * @param {Function} predicate
 * @returns {string[]|number[]|boolean[]}
 */
export function unique(list, predicate = null) {
    return predicate ? [...new Set(list.map(predicate))] : [...new Set(list)];
}

/**
 * Creates an array of unique objects by a given key.
 * @param {Object[]} list
 * @param {string|function} key
 * @returns {Object[]}
 */
export function uniqueObjects(list, key) {
    let pluck = key;

    if (typeof key !== 'function') {
        pluck = item => item[key];
    }

    return [...new Map(list.map(item => [pluck(item), item])).values()];
}

/**
 * Creates an array of chunks of a given size
 * @param {Object[]} array
 * @param {number} size
 * @returns {Array[]}
 */
export function chunk(array, size) {
    return array.reduce((accumulator, currentValue, index) => {
        const chunkIndex = Math.floor(index / size);

        if (!accumulator[chunkIndex]) {
            accumulator[chunkIndex] = [];
        }

        accumulator[chunkIndex].push(currentValue);

        return accumulator;
    }, []);
}

/**
 * Creates an object by mapping keys and values arrays. Example: zipObject(['a', 'b'], [1, 2]) // { 'a': 1, 'b': 2 }
 * @param {string[]} keys
 * @param {*[]} values
 * @returns {Object}
 */
export function zipObject(keys, values) {
    return Object.fromEntries(keys.map((type, index) => [type, values[index]]));
}

import subsidiaryMoment from 'moment-timezone';

function setupMoment(subsidiary) {
    subsidiaryMoment.updateLocale('en-ca', {
        longDateFormat: {
            L: 'MM/DD/YYYY',
        },
    });

    subsidiaryMoment.updateLocale('fr-ca', {
        longDateFormat: {
            L: 'MM/DD/YYYY',
        },
    });

    if (subsidiary) {
        subsidiaryMoment.tz.setDefault(subsidiary.timeZone);
        subsidiaryMoment.locale(subsidiary.defaultLocale);
    }
}

export {
    setupMoment,
};

/**
 * @deprecated
 * Avoid using moment.js unless absolutely necessary. Instead, use date util functions from `generic.js`
 * @see https://momentjs.com/docs/#/-project-status
 */
export default subsidiaryMoment;

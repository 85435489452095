<template>
    <li
        class="relative flex items-center w-full h-full bg-primary"
        :class="notification.read ? 'cursor-default' : 'cursor-pointer'"
        :title="notification.read ? undefined : $t('notifications.markAsRead')"
        @click.stop="markAsREad"
    >
        <div
            class="absolute top-0 left-0 w-4 h-full"
            :class="notification.read ? 'bg-white' : 'bg-primary'"
        />
        <div
            class="flex flex-col gap-2 px-8 py-4 w-full bg-white text-primary"
            :class="{'hover:bg-grey-light': !notification.read}"
        >
            <div class="flex items-baseline gap-2">
                <i
                    class="fa"
                    :class="notification.iconCss"
                />
                <div class="flex flex-col">
                    <p class="leading-snug">
                        <NotificationMessage
                            :message="notification.message"
                            @linkClick="handleLinkClick"
                        />
                    </p>
                    <span
                        class="text-sm text-grey-dark w-fit"
                        :title="formatDate(notification.createdAt)"
                    >
                        {{ formatToRelativeTime(notification.createdAt) }}
                    </span>
                </div>
            </div>
        </div>
    </li>
</template>

<script setup>
import { bugsnag } from '@/libs/bugsnag.js';
import NotificationMessage from '@/modules/notifications/partials/NotificationMessage.vue';
import store from '@/store/store.js';
import { localisedDateTimeFormat, toRelativeDateFormat } from '@/utils/generic.js';

const props = defineProps({
    notification: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits([
    'urlClick',
]);

const handleLinkClick = url => {
    markAsREad();
    emit('urlClick');

    window.location = url;
};

const markAsREad = async () => {
    if (props.notification.read) {
        return;
    }

    try {
        await store.dispatch('setNotificationRead', props.notification.id);
    } catch (error) {
        bugsnag.notify(error);
    }
};

const formatDate = dateString => localisedDateTimeFormat(new Date(dateString), store.getters.userLocale, { dateStyle: 'medium', timeStyle: 'short' });
const formatToRelativeTime = dateString => toRelativeDateFormat(new Date(dateString), store.getters.userLocale, { dateStyle: 'medium', timeStyle: 'short' });

</script>

<template>
    <DropDownMenu>
        <template #title>
            {{ $t('adminMainMenu.statistics') }}
        </template>
        <template #dropdown>
            <li>
                <router-link to="/statistics/image-availability">
                    {{ $t('adminMainMenu.imageAvailability') }}
                </router-link>
            </li>
            <li>
                <router-link to="/statistics/reports">
                    {{ $t('statisticsMenu.reports') }}
                </router-link>
            </li>
            <li v-if="canViewGlobalAdministration">
                <router-link to="/statistics/global-administration">
                    {{ $t('adminMainMenu.globalAdministration') }}
                </router-link>
            </li>
        </template>
    </DropDownMenu>
</template>

<script>
import DropDownMenu from '@/components/menu/DropDownMenu.vue';

export default {
    name: 'StatisticsDropdownMenu',

    components: {
        DropDownMenu,
    },

    computed: {
        canViewGlobalAdministration() {
            return this.$store.getters.getUserCan('canViewGlobalAdministration');
        },
    },
};
</script>

import api, { createCustomApiInstance, createTransformingApiInstance } from '@/api/api.js';

const transformingApi = createTransformingApiInstance();
const customApiInstance = createCustomApiInstance({}, {
    transformRequest: true,
    transformResponse: false,
    wrappedResponse: true,
});

export function getVendorName(shipViaNumber) {
    return api.get(`basket/${shipViaNumber}/vendor`);
}

export function getPendingBaskets(params) {
    return transformingApi.get('baskets/pending', { params });
}

export function downloadBaskets(config) {
    return customApiInstance.get('baskets/pending/export', config);
}

export function removeSalesRepCustomersBasketLines(payload) {
    return api.post('basket/removeSalesRepCustomersBasketLines', payload);
}

export function getManageableCustomersBasketsByBasketNo(basketNo) {
    return api.get(`basket/getManageableCustomersBasketsByBasketNo/${basketNo}`);
}

export function createBasket(payload) {
    return api.post('baskets', payload);
}

/**
 * Gets current basket for customer
 * @param {Object} [params]
 * @param {boolean|null} [params.customized] Should only be provided when in custom order context
 * @param {string|null} [params.customerNo] Only applicable when called from sales rep context
 * @param {string|null} [params.orderJourney] One of `at-once` or `preorder`
 * @returns {Promise<*>}
 */
export function getCurrentBasket(params) {
    return api.get('baskets/current', { params });
}

export function getBasket(basketNo, params) {
    return api.get(`baskets/${basketNo}`, { params });
}

/**
 * Updates basket properties
 * @param {Number} basketNo
 * @param {Object} payload
 * @param {boolean} transform
 * @returns {Promise<*>}
 */
export function updateBasket(basketNo, payload, transform = false) {
    const resolvedApi = transform ? transformingApi : api;

    return resolvedApi.patch(`baskets/${basketNo}`, payload);
}

export function deleteBasket(basketNo) {
    return api.delete(`baskets/${basketNo}`);
}

export function archiveBasket(basketNo) {
    return api.patch(`baskets/${basketNo}/archive`);
}

export function copyBasket(payload) {
    return api.post('basket/copyBasket', payload);
}

export function updateBasketFreeOfCharge(basketNo, payload) {
    return transformingApi.patch(`baskets/${basketNo}/free-of-charge`, payload);
}

export function updateBasketLines(basketNo, payload) {
    return api.patch(`baskets/${basketNo}/lines`, payload);
}

export function updateCustomizedBasketData(basketNo, payload) {
    return transformingApi.patch(`baskets/${basketNo}/customized-data`, payload);
}

export function removeAllItemsFromBasket(basketNo) {
    return api.delete(`baskets/${basketNo}/items`);
}

export function removeItemsPerDateFromBasket(basketNo, params) {
    return api.delete(`baskets/${basketNo}/items`, { params });
}

export function removeItemFromBasket(basketNo, itemNo, params) {
    return api.delete(`baskets/${basketNo}/items/${itemNo}`, { params });
}

export function getCurrentBasketMenuFilters(params) {
    return api.get('baskets/current-basket-menu-filters', { params });
}

export function createBasketFromOrder(orderNo, payload) {
    return transformingApi.post(`orders/${orderNo}/baskets`, payload);
}

export function getEarliestAvailableDeliveryDate(orderNo, params) {
    return transformingApi.get(`orders/${orderNo}/earliest-available-delivery-date`, { params });
}

import { transformToCamelCase } from '@/api/transformers.js';
import { useToastNotification } from '@/composables/use-toast-notification.js';
import { laravelEchoClient } from '@/libs/laravel-echo.js';
import store from '@/store/store.js';
import { isObject } from '@/utils/collection.js';
import { Const } from '@/utils/constants.js';

const { showToastNotification } = useToastNotification();
const abortControllers = new Map();

function setHeaders(requestConfig) {
    const authenticated = store.getters['globalStore/authenticated'];
    const subsidiary = store.getters['globalStore/subsidiary'];
    const accessToken = (new URL(location.href)).searchParams.get('accessToken');

    if (authenticated && subsidiary && requestConfig.url !== 'public/geolocations') {
        requestConfig.baseURL = `/${subsidiary.key}`;
        requestConfig.headers['x-subsidiary'] = subsidiary.key;
    } else {
        requestConfig.baseURL = '/api';
    }

    // Laravel will extract the socket ID from the header and instruct the broadcaster to not broadcast to any connections with that socket ID when `toOthers` is used.
    // https://laravel.com/docs/9.x/broadcasting#only-to-others-configuration
    if (laravelEchoClient) {
        requestConfig.headers['X-Socket-ID'] = laravelEchoClient.socketId();
    }

    if (accessToken) {
        requestConfig.headers.Authorization = `Bearer ${accessToken}`;
    }
}

const requestInterceptor = requestConfig => {
    const abortController = new AbortController();

    abortControllers.set(requestConfig.url, abortController);

    setHeaders(requestConfig);

    return {
        signal: abortController.signal,
        ...requestConfig,
    };
};

const responseSuccessInterceptor = response => {
    abortControllers.delete(response.config.url);

    if (['patch', 'put'].includes(response.config.method) && [Const.HTTP_STATUS.OK, Const.HTTP_STATUS.NO_CONTENT].includes(response.status)) {
        showToastNotification({
            type: 'success',
        });
    }

    return response;
};

const responseErrorInterceptor = async (error, options = { transformResponse: false }) => {
    error.response = options.transformResponse ? transformToCamelCase(error.response) : error.response;

    if (error.response?.status === Const.HTTP_STATUS.SERVICE_UNAVAILABLE && error.config?.method === 'get') {
        await store.dispatch('globalStore/setUnauthenticated');

        // Temporary solution where backend page is returned. TODO: create Vue maintenance component with translations in different languages
        window.location.href = `${error.config.baseURL}/${error.config.url}`;
    }

    if ([Const.HTTP_STATUS.UNAUTHENTICATED, Const.HTTP_STATUS.CSRF_TOKEN_MISMATCH].includes(error.response?.status)) {
        await store.dispatch('globalStore/setUnauthenticated');

        // Cancel all pending requests because otherwise they will make redundant calls and fail with 401
        for (const [, controller] of abortControllers) {
            controller.abort();
        }

        abortControllers.clear();
    }

    if (error.response?.status === Const.HTTP_STATUS.UNPROCESSABLE_CONTENT && isObject(error.response?.data?.errors)) {
        store.commit('setApiErrors', error.response.data.errors);
    }

    return Promise.reject(error);
};

export {
    requestInterceptor,
    responseSuccessInterceptor,
    responseErrorInterceptor,
};

<template>
    <div>
        <div class="fixed top-0 right-0 bottom-0 left-0 z-[1000] backdrop-blur-[2px] bg-black/30" />
        <div
            class="vue-modal mx-1"
            @click.self="close"
        >
            <div
                class="asics-modal-dialog"
                :class="sizeCSSClass"
            >
                <div class="vue-modal-content relative bg-white border border-grey-light rounded-xl md:rounded-3xl shadow-xl md:shadow-2xl overflow-hidden">
                    <div
                        class="flex items-baseline p-6 border-b border-grey-light"
                        :class="{'border-none': noHeaderTitle}"
                    >
                        <h3
                            v-if="!noHeaderTitle"
                            class="w-full m-0 capitalize"
                        >
                            <slot name="header-title" />
                        </h3>
                        <ButtonPlain
                            v-if="closable"
                            class="text-xl flex-none h-12 w-12 !bg-secondary-lightest rounded-full ml-auto"
                            @click.native="close"
                        >
                            <i class="fa fa-close" />
                        </ButtonPlain>
                    </div>
                    <div
                        v-if="hasSlot('header') || hasSlot('message') || hasSlot('body')"
                        class="relative font-normal"
                        :class="{
                            'p-0': bodyPadding === 'none',
                            'p-6': bodyPadding === 'medium',
                        }"
                    >
                        <h1 v-if="hasSlot('header')">
                            <slot name="header" />
                        </h1>
                        <p v-if="hasSlot('message')">
                            <slot name="message" />
                        </p>
                        <slot name="body" />
                    </div>
                    <div
                        v-if="hasSlot('default')"
                        class="p-6 border-t border-grey-light"
                    >
                        <slot />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonPlain from '@/components/button/ButtonPlain.vue';

export default {
    name: 'ModalDialog',

    components: {
        ButtonPlain,
    },

    props: {
        size: {
            type: String,
            default: 'medium',
            validator(value) {
                const isValid = ['small', 'medium', 'large'].includes(value);

                if (!isValid) {
                    console.warn(`Invalid modal size prop: ${value}`);
                }

                return isValid;
            },
        },

        bodyPadding: {
            type: String,
            default: 'medium',
            validator(value) {
                const isValid = ['none', 'medium'].includes(value);

                if (!isValid) {
                    console.warn(`Invalid modal padding prop: ${value}`);
                }

                return isValid;
            },
        },

        closable: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            open: true,
        };
    },

    computed: {
        noHeaderTitle() {
            return !this.hasSlot('header-title');
        },

        sizeCSSClass() {
            return `modal-${this.size}`;
        },
    },

    mounted() {
        document.addEventListener('keyup', this.handleKeyup);
    },

    beforeDestroy() {
        document.removeEventListener('keyup', this.handleKeyup);
    },

    methods: {
        hasSlot(name = 'default') {
            return Boolean(this.$slots[name] || this.$scopedSlots[name]);
        },

        close() {
            if (this.closable) {
                this.$emit('close');
            }
        },

        handleKeyup(event) {
            if (event.code === 'Escape') {
                this.close();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.vue-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1050;
    display: block;
}

.asics-modal-dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: calc(100% - 2rem);
    max-height: calc(100% - 2rem);

    &.modal-small {
        @apply max-w-3xl;
    }

    &.modal-medium {
        @apply max-w-screen-xl;
    }

    &.modal-large {
        @apply max-w-screen-2xl;
    }
}
</style>

<template>
    <div
        v-if="slides.length"
        class="max-w-[33rem] sm:max-w-screen-md md:max-w-[100rem] mx-auto overflow-hidden"
    >
        <VueSlickCarousel
            v-bind="settings"
            @beforeChange="beforeSlideChange"
        >
            <div
                v-for="slide in slides"
                :key="slide.id"
            >
                <img
                    :src="slide.picture_path"
                    alt=""
                >
            </div>

            <template #customPaging="index">
                <ButtonPlain :class="index === currentSlideIndex ? 'text-primary' : 'text-secondary-light'">
                    <i class="fa fa-circle fa-xs" />
                </ButtonPlain>
            </template>
        </VueSlickCarousel>
    </div>
</template>

<script>
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import { bugsnag } from '@/libs/bugsnag.js';
import { loadSlides } from '@/modules/admin/settings/admin.home.api.js';
import VueSlickCarousel from 'vue-slick-carousel';

export default {
    name: 'DashboardSlideshow',

    components: {
        ButtonPlain,
        VueSlickCarousel,
    },

    data() {
        return {
            currentSlideIndex: 0,
            slides: [],
            settings: {
                arrows: false,
                dots: true,
                autoplay: true,
                autoplaySpeed: 5000,
                pauseOnFocus: true,
                variableWidth: false,
                fade: true,
                lazyLoad: 'progressive',
            },
        };
    },

    created() {
        this.loadSlides();
    },

    methods: {
        async loadSlides() {
            try {
                const { data } = await loadSlides();

                this.slides.push(...data.data);

                this.settings.variableWidth = this.slides.length === 1; // vue-slick-carousel incorrectly calculates slide container width when there's only 1 slide. This forces correct width
            } catch (error) {
                bugsnag.notify(error);
            }
        },

        beforeSlideChange(oldIndex, newIndex) {
            this.currentSlideIndex = newIndex;
        },
    },
};
</script>

<style scoped>
:deep(.slick-track) {
    @apply flex;
}

:deep(.slick-dots) {
    @apply !flex items-center justify-center gap-x-4 h-6;
}
</style>

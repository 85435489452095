<template>
    <ModalDialog
        size="small"
        :closable="false"
        @close="cancel"
    >
        <template #header-title>
            {{ title ?? $t('general.titleConfirmation') }}
        </template>

        <template #message>
            {{ $t('general.confirmationMessage') }}
        </template>

        <div class="action-buttons">
            <Button
                kind="primary-alt"
                :text="$t('general.buttonNo')"
                @click.native="cancel"
            />
            <Button
                :text="$t('general.buttonYes')"
                @click.native="confirm"
            />
        </div>
    </ModalDialog>
</template>

<script setup>
import Button from '@/components/button/Button.vue';
import ModalDialog from '@/components/dialogs/ModalDialog.vue';

defineProps({
    title: {
        type: String,
        default: null,
    },
});

const emit = defineEmits([
    'cancel',
    'confirm',
]);

const cancel = () => {
    emit('cancel');
};

const confirm = () => {
    emit('confirm');
};

</script>
